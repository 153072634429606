import { cloneDeep } from 'lodash-es';
import { LegacyComposerDocument, LegacyComposerSectionProps, SectionTypes } from '../../../types';
import {
  migrateButtonSection,
  migrateCustomCodeSection,
  migrateFooterSection,
  migrateImageSection,
  migrateLogoSection,
  migrateTextSection,
} from '../../../utils/legacy-migration';

export const emailComposerMigrateLegacyToSchemaType = (
  parsedTemplateJson: LegacyComposerDocument
): { formattedSections?: { sections?: SectionTypes[] }; invalidSections?: LegacyComposerSectionProps[] } => {
  if (!parsedTemplateJson) return { formattedSections: undefined };
  const oldSections = cloneDeep(parsedTemplateJson.sections);
  if (!oldSections || !Array.isArray(oldSections)) return { formattedSections: undefined };
  const invalidSections: LegacyComposerSectionProps[] = [];
  const sections = oldSections
    ?.map((section) => {
      const type = section.type;

      switch (type) {
        case 'ButtonSection':
          return migrateButtonSection(section);
        case 'CustomCodeSection':
          return migrateCustomCodeSection(section);
        case 'FooterSection':
          return migrateFooterSection(section);
        case 'ImageSection':
          return migrateImageSection(section);
        case 'LogoSection':
          return migrateLogoSection(section);
        case 'TextSection':
          return migrateTextSection(section);
        default:
          invalidSections.push(section);
          return undefined;
      }
    })
    .filter((section): section is NonNullable<SectionTypes> => !!section);

  return { formattedSections: { sections }, invalidSections };
};
