import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { HEADER_HEIGHT } from '../constants';
import { useMiniChatShallowStore } from '../stores';

export const useMiniChatBottomStyling = (): number => {
  const { chats, rolledUp } = useMiniChatShallowStore('chats', 'rolledUp');
  const isMobileMiniChats = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const show = !!chats.length && !rolledUp && !isMobileMiniChats;
  return show ? HEADER_HEIGHT : 0;
};
