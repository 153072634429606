import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, usePopoverDialog } from '@frontend/design-system';
import { EmojiPickerPopover } from './emoji-picker-popover';

export const EmojiButton = (props: ReturnType<typeof usePopoverDialog>) => {
  return (
    <>
      <Button
        variant='secondary'
        {...props.getTriggerProps()}
        css={{ padding: theme.spacing(0.125, 1), width: theme.spacing(5) }}
      >
        <Icon name='emoji' />
      </Button>
      <EmojiPickerPopover {...props} />
    </>
  );
};
