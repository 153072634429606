import { useEffect, useState } from 'react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { PopoverDialog, usePopoverDialog } from '@frontend/design-system';
import { useEmailSubjectHeader } from '../email-subject-header.context';

const EMOJI_PICKER_WIDTH = 350;
const EMOJI_PICKER_HEIGHT = 450;

export const EmojiPickerPopover = (props: ReturnType<typeof usePopoverDialog>) => {
  const { setSubject, subject, subjectProps } = useEmailSubjectHeader();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const onSelect = (emoji: EmojiClickData) => {
    // using the subject instead of subjectProps.value because the value gets trimmed when the focus is removed from the input field
    const newSubject = subject + emoji.emoji;
    subjectProps?.onChange({ ...subjectProps, value: newSubject, name: 'subject' });
    setShowEmojiPicker(false);
    setSubject(newSubject);
    props.close();
  };

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;

    if (!props.isOpen) {
      setShowEmojiPicker(false);
    }

    if (props.isOpen) {
      id = setTimeout(() => setShowEmojiPicker(true), 400);
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [props.isOpen]);

  return (
    <PopoverDialog
      {...props.getDialogProps()}
      css={{
        width: EMOJI_PICKER_WIDTH,
        maxHeight: EMOJI_PICKER_HEIGHT,
        padding: 0,
        borderRadius: theme.borderRadius.medium,
      }}
      returnFocus={false}
    >
      <div
        css={{
          width: EMOJI_PICKER_WIDTH,
          height: EMOJI_PICKER_HEIGHT,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {showEmojiPicker && (
          <EmojiPicker
            css={{ fontFamily: theme.font.family }}
            onEmojiClick={onSelect}
            lazyLoadEmojis
            height={EMOJI_PICKER_HEIGHT}
            width={EMOJI_PICKER_WIDTH}
          />
        )}
      </div>
    </PopoverDialog>
  );
};
