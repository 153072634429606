import { css } from '@emotion/react';
import { isEmail } from 'validator';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { EmailField, Modal, ModalControlModalProps, SpinningLoader, Text, useFormField } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../../../constants';
import { useEmailSubjectHeader } from '../email-subject-header.context';

type Props = {
  hasSubject: boolean;
  loading: boolean;
  modalProps: ModalControlModalProps;
  sendTestEmail: (passedEmailAddress: string) => void;
};

const EMAIL_ADDRESS_KEY = 'email-address';

// TODO: When unfocusing the chip field, entered text should not be erased and should persist
// TODO: Handle rate limiting. If the user sends too many test emails in a short period of time, the backend will return a 429 error that we should handle
export const SendTestEmailModal = ({ hasSubject, loading, modalProps, sendTestEmail }: Props) => {
  const { t } = useTranslation('email-composer');
  const { trackingPrefix } = useEmailSubjectHeader();

  const saveEmailAddress = (emailAddress: string) => {
    sessionStorage.setItem(EMAIL_ADDRESS_KEY, JSON.stringify(emailAddress));
  };

  const loadEmailAddress = () => {
    const savedEmailAddress = sessionStorage.getItem(EMAIL_ADDRESS_KEY);
    return savedEmailAddress ? JSON.parse(savedEmailAddress) : '';
  };

  const handleSubmit = () => {
    const emailAddress = fieldProps.value;
    saveEmailAddress(emailAddress);
    sendTestEmail(emailAddress);
  };

  const fieldProps = useFormField({ type: 'text', value: loadEmailAddress() || '' });

  return (
    <Modal
      {...modalProps}
      minWidth={700}
      css={css`
        @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
          min-width: min(95%, 700px);
        }
      `}
    >
      <Modal.Header>{t('Send Test Email')}</Modal.Header>
      <Modal.Body css={{ padding: theme.spacing(2) }}>
        <EmailField {...fieldProps} label={t('To:')} name='' />
        {!hasSubject && (
          <Text
            size='medium'
            color='light'
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: theme.spacing(1),
            }}
          >
            <Icon
              name='info-small'
              color='light'
              css={{
                display: 'inline-block',
                marginRight: theme.spacing(0.5),
              }}
            />
            {!hasSubject && t('This email will have a subject of "Test Email".')}
          </Text>
        )}
        {loading && (
          <Text
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: theme.spacing(4),
            }}
          >
            <SpinningLoader size='xs' css={{ marginRight: theme.spacing(0.5) }} />
            {t('Sending test email...')}
          </Text>
        )}
      </Modal.Body>
      <Modal.Actions
        secondaryLabel={t('Cancel')}
        onSecondaryClick={() => true}
        primaryLabel={t('Send Test')}
        onPrimaryClick={handleSubmit}
        disablePrimary={loading || !fieldProps.value || !isEmail(fieldProps.value)}
        primaryTrackingId={`${trackingPrefix}-send-test-email-modal-send-btn`}
        secondaryTrackingId={`${trackingPrefix}-send-test-email-modal-cancel-btn`}
      />
    </Modal>
  );
};
