import { DragAndDrop } from '../../../constants';

const droppablePrefixes = {
  fieldCategory: 'field-category',
};

/**
 * @param categoryLabel - The label of the field category.
 * @returns The droppable ID for the field category (to hold the fields to drag from).
 */
export function getFieldCategoryDroppableId(categoryLabel: string): string {
  return [droppablePrefixes.fieldCategory, categoryLabel].join(DragAndDrop.SEPARATOR);
}
