import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelQueries, CallIntelTypes } from '@frontend/api-call-intel';
import { Chart } from '@frontend/charts';
import { useTranslation, i18next } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { formatters } from '../../../utils';
import { CallIntelInfoTips } from '../call-intel-info-tips';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelLocations, useCallIntelShallowStore } from '../hooks';
import { CustomToolTipTitle } from '../insight-card';

// Types
type GetAggregatedValuesParams = {
  aggregates: CallIntelTypes.HourlyAggregatesByDay[];
  date: string;
  firstHourIndex: number;
  index: number;
  isSameDay: boolean;
};

type generateAggregatedDataParams = {
  startDate: string;
  endDate: string;
  rawData: CallIntelTypes.ServiceQualityCallVolumeResponse;
};

type ChartDataGroup = {
  name: string;
  values: {
    callsAnalyzed: number;
    excellentService: number;
    unresolved: number;
  };
};

type ChartData = {
  groups: ChartDataGroup[];
  totalCallsAnalyzed: number;
  totalExcellentService: number;
  totalUnresolved: number;
};

// Constants
const demoLocations = CallIntelMockData.dummyLocationNames();

const chartConfig = {
  colors: {
    callsAnalyzed: theme.colors.neutral30,
    excellentService: theme.colors.primary60,
    unresolved: theme.colors.critical40,
  },
  labels: {
    callsAnalyzed: i18next.t('Calls Analyzed', {
      ns: 'analytics',
    }),
    excellentService: i18next.t('Excellent Service', {
      ns: 'analytics',
    }),
    unresolved: i18next.t('Unresolved', {
      ns: 'analytics',
    }),
  },
};

// Utility functions

// Utility function to generate a range of dates between start and end date
const generateDateRange = (startDate: string, endDate: string, format = 'YYYY-MM-DD'): string[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const dates = [];
  let current = start;

  while (current.isBefore(end) || current.isSame(end, 'day')) {
    dates.push(current.format(format));
    current = current.add(1, 'day');
  }

  return dates;
};

// Function to find the first active hour with a non-zero value
const getFirstActiveHourIndex = (aggregates: CallIntelTypes.HourlyAggregatesByDay[]): number => {
  const hours = aggregates[0]?.values || [];
  return hours.findIndex((value) => +value > 0);
};

// Function to find the last active hour with a non-zero value
const getLastActiveHourIndex = (aggregates: CallIntelTypes.HourlyAggregatesByDay[]): number => {
  const hours = aggregates[0]?.values || [];
  // Find the last non-zero value by iterating from the end
  for (let i = hours.length - 1; i >= 0; i--) {
    if (+hours[i] > 0) {
      return i;
    }
  }
  return hours.length - 1; // Default to the last hour if no data is found
};

// Function to generate hourly slots from the first active hour
const generateHourlySlots = (startDate: string, firstActiveHourIndex: number, lastActiveHourIndex: number) => {
  const allHours = Array.from({ length: 24 }, (_, i) =>
    dayjs(startDate).startOf('day').add(i, 'hour').format('hh:mm A')
  );

  // Reorder the slots so that hours before the first active hour come at the end
  return allHours.slice(firstActiveHourIndex, lastActiveHourIndex + 1);
};

// Function to get aggregated values based on date or hour
const getAggregatedValues = ({ aggregates, date, index, isSameDay, firstHourIndex }: GetAggregatedValuesParams) => {
  const aggregate = aggregates.find((item) => item.date === date);
  if (!aggregate) return 0;

  const adjustedIndex = isSameDay ? (index + firstHourIndex) % 24 : index;
  return isSameDay
    ? Number(aggregate.values[adjustedIndex] || 0)
    : aggregate.values.reduce((sum, val) => sum + Number(val), 0);
};

// Function to generate the aggregated data structure for the chart
const generateAggregatedData = ({ startDate, endDate, rawData }: generateAggregatedDataParams) => {
  const isSameDay = dayjs(startDate).isSame(dayjs(endDate), 'day');
  const dateFormat = isSameDay ? 'hh:mm A' : 'MMM D';

  const { callsAnalyzed, excellentService, unresolved } = rawData;
  const firstActiveHourIndex = isSameDay ? getFirstActiveHourIndex(callsAnalyzed.aggregates) : 0;
  const lastActiveHourIndex = isSameDay ? getLastActiveHourIndex(callsAnalyzed.aggregates) : 23;

  const dataSlots = isSameDay
    ? generateHourlySlots(startDate, firstActiveHourIndex, lastActiveHourIndex)
    : generateDateRange(startDate, endDate);

  const groups = dataSlots.map((slot, index) => {
    const dateKey = isSameDay ? dayjs(startDate).format('YYYY-MM-DD') : slot;

    return {
      name: isSameDay ? slot : dayjs(slot).format(dateFormat),
      values: {
        callsAnalyzed: getAggregatedValues({
          aggregates: callsAnalyzed.aggregates,
          date: dateKey,
          firstHourIndex: firstActiveHourIndex,
          index,
          isSameDay,
        }),
        excellentService: getAggregatedValues({
          aggregates: excellentService.aggregates,
          date: dateKey,
          firstHourIndex: firstActiveHourIndex,
          index,
          isSameDay,
        }),
        unresolved: getAggregatedValues({
          aggregates: unresolved.aggregates,
          date: dateKey,
          firstHourIndex: firstActiveHourIndex,
          index,
          isSameDay,
        }),
      },
    };
  });

  const totalCallsAnalyzed = groups.reduce((sum, group) => sum + group.values.callsAnalyzed, 0);
  const totalExcellentService = groups.reduce((sum, group) => sum + group.values.excellentService, 0);
  const totalUnresolved = groups.reduce((sum, group) => sum + group.values.unresolved, 0);

  const finalGroups = totalCallsAnalyzed === 0 ? [] : groups;

  return {
    groups: finalGroups,
    totalCallsAnalyzed,
    totalExcellentService,
    totalUnresolved,
  };
};

export const ServiceQualityCallVolume = () => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const [chartData, setChartData] = useState<ChartData | undefined>();
  const [isSameDay, setIsSameDay] = useState(false);

  const { filterHintText, filters, isDemoAccount } = useCallIntelShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );

  const { isMultiLocation } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });

  const { data, isFetching } = useScopedQuery({
    enabled: !isDemoAccount,
    queryKey: queryKeys.callIntelligence(`service-quality-chart-call-volume-${JSON.stringify(filters)}`),
    queryFn: () => CallIntelligenceApi.getServiceQualityCallVolume(filters),
    onError: (err) => {
      alert.error(t('Failed to fetch call volume stats'));
      console.error(err);
    },
    refetchOnWindowFocus: false,
    select: (data) => data as CallIntelTypes.ServiceQualityCallVolumeResponse,
    staleTime: 1000 * 60 * 5,
  });

  const { data: demoData, isFetching: isDemoDataFetching } = CallIntelQueries.useGetServiceQualityChartStats({
    isDemoAccount,
    payload: {
      filters,
      includes: { serviceQuality: true },
    },
    realQueryOptions: {
      enabled: false,
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        alert.error(t('Failed to fetch stats'));
        console.error(err);
      },
    },
    demoQueryOptions: {
      select: (data) => data as CallIntelTypes.DemoServiceQualityChartStatsResponse,
    },
  });

  useEffect(() => {
    const startDate = filters.startDate;
    const endDate = filters.endDate;
    const rawData = isDemoAccount
      ? (demoData as CallIntelTypes.DemoServiceQualityChartStatsResponse)?.callVolume
      : data;

    if (rawData && endDate && startDate) {
      const aggregatedData = generateAggregatedData({ startDate, endDate, rawData });
      setChartData(aggregatedData);
      setIsSameDay(dayjs(startDate).isSame(dayjs(endDate), 'day'));
    }
  }, [data, demoData, filters]);

  return (
    <Chart
      colors={chartConfig.colors}
      isLoading={isDemoAccount ? isDemoDataFetching : isFetching}
      emptyStateConfig={{
        description: t('Choose a different time period or adjust your filters.'),
        label: t('No data to display.'),
      }}
      labels={chartConfig.labels}
    >
      <Chart.Header
        infoTip={<CallIntelInfoTips tip='serviceQualityCallVolume' />}
        subtitle={filterHintText}
        title={t('Service Quality Call Volume')}
      />
      <Chart.AreaChart
        appearance={{
          showXAxis: true,
          showYAxis: true,
          customXAxisTickFormat: (value, idx) => (!isSameDay || idx % 2 === 0 ? `${value}` : ''),
          customTooltipTitle: ({ groupName }) => (
            <CustomToolTipTitle
              label={
                <Text as='span' weight='bold' color='subdued' size='small'>
                  {groupName}
                </Text>
              }
              isMultiLocation={isMultiLocation}
              locationCount={filters?.locations?.length ?? 0}
            />
          ),
          height: 300,
        }}
        data={chartData}
        formatValue={formatters.value.format}
        multiAxisClubIds={{
          callsAnalyzed: 'value',
          excellentService: 'value',
          unresolved: 'value',
        }}
      />
      {!!chartData?.groups.length && <Chart.Legends fullSpacing style={{ marginTop: 8 }} />}
    </Chart>
  );
};
