import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { Chart } from '@frontend/charts';
import { useTranslation, i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { URLs } from '../../../constants';
import { CallIntelInfoTipId, CallIntelInfoTips } from '../call-intel-info-tips';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelLocations, useCallIntelShallowStore } from '../hooks';
import { CustomToolTipTitle } from '../insight-card';

type ChartsConfigParams = Record<
  CallIntelTypes.ServiceQualityFlag,
  {
    color: string;
    chartColor: string;
    dataInfoTipId: CallIntelInfoTipId;
    dataLabel: string;
    iconName: IconName;
    infoTipId: CallIntelInfoTipId;
    strokeColor: string;
    title: string;
  }
>;

export type ServiceQualityGaugeChartStats = {
  currentRate: number;
  value: number;
  totalValue: number;
};

type Props = {
  isDemoAccount?: boolean;
  isLoading: boolean;
  stats: ServiceQualityGaugeChartStats;
  flag: CallIntelTypes.ServiceQualityFlag;
};

const demoLocations = CallIntelMockData.dummyLocationNames();

const chartsConfig: ChartsConfigParams = {
  [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: {
    color: theme.colors.critical50,
    chartColor: theme.colors.critical30,
    dataInfoTipId: 'unresolved',
    dataLabel: i18next.t('Unresolved', {
      ns: 'analytics',
    }),
    iconName: 'alert',
    infoTipId: 'callsToReview',
    strokeColor: theme.colors.critical50,
    title: i18next.t('Calls to Review', {
      ns: 'analytics',
    }),
  },
  [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: {
    color: theme.colors.primary60,
    chartColor: theme.colors.primary40,
    dataInfoTipId: 'excellentService',
    dataLabel: i18next.t('Excellent Service', {
      ns: 'analytics',
    }),
    iconName: 'crown',
    infoTipId: 'spotLightedCalls',
    strokeColor: theme.colors.primary60,
    title: i18next.t('Spotlighted Calls', {
      ns: 'analytics',
    }),
  },
};

const generateTooltipData = (
  id: string,
  stats: ServiceQualityGaugeChartStats,
  flag: CallIntelTypes.ServiceQualityFlag
) => {
  const tooltipData = {
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: [
      {
        color: theme.colors.critical50,
        formattedValue: stats.value,
        id,
        label: i18next.t('Unresolved Calls', {
          ns: 'analytics',
        }),
        subLabel: i18next.t('Out of {{totalValue}} Calls Analyzed', {
          ns: 'analytics',
          totalValue: stats.totalValue,
        }),
      },

      {
        color: theme.colors.critical50,
        formattedValue: `${stats.currentRate}%`,
        id,
        label: i18next.t('Unresolved Call Rate', {
          ns: 'analytics',
        }),
      },
    ],
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: [
      {
        color: theme.colors.primary60,
        formattedValue: stats.value,
        id,
        label: i18next.t('Excellent Service Calls', {
          ns: 'analytics',
        }),
        subLabel: i18next.t('Out of {{totalValue}} Calls Analyzed', {
          ns: 'analytics',
          totalValue: stats.totalValue,
        }),
      },

      {
        color: theme.colors.primary60,
        formattedValue: `${stats.currentRate}%`,
        id,
        label: i18next.t('Excellent Service Rate', {
          ns: 'analytics',
        }),
      },
    ],
  };

  return tooltipData[flag];
};

export const ServiceQualityGuageChart = ({ isLoading, isDemoAccount, flag, stats }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();

  const { isMultiLocation } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });

  const { filterHintText, filters, setFiltersToRestore, setSubView } = useCallIntelShallowStore(
    'filterHintText',
    'filters',
    'setFiltersToRestore',
    'setSubView'
  );

  const chartConfig = chartsConfig[flag];

  return (
    <Chart
      colors={{
        value: chartConfig.chartColor,
        strokeColor: chartConfig.strokeColor,
      }}
      emptyStateConfig={{
        description: t('Choose a different time period or adjust your filters.'),
        label: t('No data to display.'),
        hideEmptyState: stats.totalValue ? true : false,
      }}
      isLoading={isLoading}
      css={css`
        .recharts-wrapper {
          > svg {
            color: ${chartConfig.color};
          }
        }
      `}
    >
      <Chart.Header
        bottomElement={
          <Text color='subdued' size='medium'>
            {t('Click the segment to learn more.')}
          </Text>
        }
        infoTip={<CallIntelInfoTips tip={chartConfig.infoTipId} />}
        subtitle={filterHintText}
        title={chartConfig.title}
      />
      <Chart.GaugeChart
        appearance={{
          customTooltipTitle: (
            <CustomToolTipTitle
              label={chartConfig.dataLabel}
              isMultiLocation={isMultiLocation}
              locationCount={filters.locations?.length ?? 0}
            />
          ),
          customTooltipData: (id) => generateTooltipData(id, stats, flag),
          height: 300,
        }}
        bottomContent={
          <div css={styles.bottomContent}>
            <Text size='large' weight='bold' css={styles.statsWrapper}>
              <Text
                as='span'
                css={[
                  styles.valueText,
                  css`
                    color: ${chartConfig.color};
                  `,
                ]}
              >
                {stats.value}
              </Text>{' '}
              {chartConfig.dataLabel}
              <CallIntelInfoTips tip={chartConfig.dataInfoTipId} />
            </Text>
            <Text color='subdued' size='small'>
              {t('Out of {{totalValue}} Calls Analyzed', { totalValue: stats.totalValue })}
            </Text>
          </div>
        }
        iconConfig={{ name: chartConfig.iconName }}
        value={stats.currentRate}
        onClick={() => {
          setFiltersToRestore(filters);

          setSubView({
            id: flag,
            type: 'service-quality',
          });

          navigate({
            search: {
              type: 'service-quality',
            },
            to: `${URLs.CALL_INTEL_BASE}/${flag.toLowerCase()}`,
          });
        }}
      />
    </Chart>
  );
};

const styles = {
  bottomContent: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: theme.spacing(1);
    gap: ${theme.spacing(0.5)};
  `,
  statsWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
  `,
  valueText: css`
    font-size: ${theme.spacing(3)};
  `,
};
