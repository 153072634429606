import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { Button } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../../../constants';
import { useContentComposer } from '../../../../content-composer-provider';

export const AddSectionButton = () => {
  const { setActivePanel } = useContentComposer();

  return (
    <Button
      variant='secondary'
      onClick={() => {
        setActivePanel('toolbox');
      }}
      css={css`
        height: 100%;
        width: fit-content;
        @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
          display: none;
        }
      `}
    >
      <Icon name='plus-small' />
    </Button>
  );
};
