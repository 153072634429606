import { BuilderTypes } from '@frontend/forms-builder-engine';
import { DragAndDrop } from '../../../constants';

const draggablePrefixes = {
  sectionTemplate: 'section-template',
};

/**
 * @param sectionTemplateKey - The key of the section template.
 * @returns The draggable ID for the section template.
 */
export function getDraggableId(
  sectionTemplateKey: BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey
): string {
  return [draggablePrefixes.sectionTemplate, sectionTemplateKey].join(DragAndDrop.SEPARATOR);
}

/**
 * @param draggableId - The draggable ID.
 * @returns The section template key.
 */
export function getSectionTemplateKey(
  draggableId: string
): BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey {
  return draggableId.split(
    DragAndDrop.SEPARATOR
  )[1] as BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey;
}
