import { EmptyStateConfig, GaugeChartAppearance } from '@frontend/charts';
import { theme } from '@frontend/theme';

export const emptyStateConfigForGauge: EmptyStateConfig<number> = {
  hideEmptyState: true,
};

export const gaugeAppearance: GaugeChartAppearance = {
  backFillColor: theme.colors.neutral5,
  backFillStrokeWidth: 0,
  borderRadius: 24,
  endAngle: -10,
  height: 82,
  hideTooltip: true,
  innerRadius: 50,
  margin: { top: 60 },
  outerRadius: 70,
  startAngle: 190,
  width: 144,
};
