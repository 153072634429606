import { theme } from '@frontend/theme';
import { Button, DynamicFieldAttributes, PopoverDialog, usePopoverDialog } from '@frontend/design-system';
import { useContentComposer } from '../../../../content-composer-provider';
import { useEmailSubjectHeader } from '../email-subject-header.context';

export const DynamicFieldPickerPopover = (props: ReturnType<typeof usePopoverDialog>) => {
  const { setSubject, subject, subjectProps } = useEmailSubjectHeader();
  const { subjectDynamicFields } = useContentComposer();

  const onSelect = (field: DynamicFieldAttributes) => {
    const newSubject = subject + '[' + field.label + ']';
    subjectProps?.onChange({ ...subjectProps, value: newSubject, name: 'subject' });
    setSubject(newSubject);
    props.close();
  };

  return (
    <PopoverDialog
      {...props.getDialogProps()}
      returnFocus={false}
      css={{
        width: 400,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      {subjectDynamicFields?.map((field) => (
        <Button
          variant='secondary'
          key={field.symbol}
          size='small'
          onClick={() => onSelect(field)}
          css={{
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            height: 'auto',
            width: 'auto',
            padding: theme.spacing(0.5, 1),
          }}
        >
          {field.label}
        </Button>
      ))}
    </PopoverDialog>
  );
};
