import {
  DynamicFieldKey_Enum as DynamicFieldKey,
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useAppScopeStore } from '@frontend/scope';

export const useDynamicFields = (templateTypeSlugs?: TemplateType_Slug[]) => {
  const { selectedLocationIds: businessGroupIds } = useAppScopeStore();

  const { data = [] } = TemplatorV2Queries.useTemplateTypesList(
    {
      businessGroupIds,
      templateTypeSlugs,
    },
    {
      select: (response) => response?.templateTypes?.[0]?.dynamicFields,
    }
  );

  const dynamicFields = data.map((field) => ({
    val: field.key ?? DynamicFieldKey.UNKNOWN,
    label: field.label ?? '',
    symbol: field.key ? '{{' + field.key + '}}' : '',
  }));

  const bindingsList = data.map((tag) => ({
    property: tag.property ?? DynamicFieldProperty_Enum.UNKNOWN,
    values: tag.exampleValues ?? [],
  }));

  return { bindingsList, dynamicFields };
};
