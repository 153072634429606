import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { MiniChatHooks } from '@frontend/mini-chat';
import { theme } from '@frontend/theme';
import { Button, CheckboxField, NakedButton, TextLink, useFormField, useModalControl } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../../constants';
import { useContentComposer } from '../../../content-composer-provider';
import { ContinueButton } from './continue-button';
import { NameYourTemplateModal } from './name-your-template-modal';
import { OnSaveChanges, OnTemplateNameChange } from './types';

type Props = {
  createTemplateAccess: boolean;
  editTemplateAccess: boolean;
  onBack: () => void;
  onSave: (data: OnSaveChanges, applyChanges: boolean) => void;
  onTemplateNameSave: (data: OnTemplateNameChange) => void;
  subject: string;
  templateTitle?: string;
  trackingPrefix: string;
};

// @editTemplateAccess - boolean to determine if the user has access to edit the template
// @params onBack - callback to handle the back button click
// @params onSave - callback function that fires when the user clicks continue
// @params onTemplateNameSave - callback function that fires when the user clicks confirm & save template
// @params subject - email subject
// @params templateTitle - template title (derived from the fetched template data)

export const EmailFooter = ({
  createTemplateAccess,
  editTemplateAccess,
  onBack,
  onSave,
  onTemplateNameSave,
  subject,
  templateTitle,
  trackingPrefix,
}: Props) => {
  const { t } = useTranslation('email-composer');
  const search = useSearch() as { templateId?: string; isEdit?: boolean };
  const templateId = search.templateId;
  const isEdit = search.isEdit === true;
  const { activePanel } = useContentComposer();
  const { modalProps, triggerProps } = useModalControl();
  const nameFieldProps = useFormField({ type: 'text', placeholder: t('New Template Title'), value: templateTitle }, [
    templateTitle,
  ]);
  const applyChangesFieldProps = useFormField({ type: 'checkbox', value: isEdit }, [isEdit]);

  // Mini Chat styling
  const bottom = MiniChatHooks.useMiniChatBottomStyling();

  return (
    <section
      className='email-footer'
      css={[
        css`
          grid-area: footer;
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: ${theme.spacing(2)};
          column-gap: ${theme.spacing(3)};
          border-top: solid 1px ${theme.colors.neutral20};
          background: ${theme.colors.white};
          position: absolute;
          width: 100%;
          bottom: ${bottom}px;
          transition: bottom 300ms ease-in-out;
          z-index: ${theme.zIndex.middle + 1};
        `,
        activePanel === 'settings' &&
          css`
            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              display: none;
            }
          `,
      ]}
    >
      <div>
        <TextLink onClick={onBack} trackingId={`${trackingPrefix}-back-btn`}>
          {t('Back')}
        </TextLink>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          column-gap: ${theme.spacing(1)};
        `}
      >
        {templateId && templateId !== 'new' && editTemplateAccess && (
          <NakedButton
            css={css`
              display: flex;
              align-items: center;
              column-gap: ${theme.spacing(1)};
              margin-right: ${theme.spacing(2)};
            `}
            trackingId={`${trackingPrefix}-apply-template-changes-btn`}
          >
            <CheckboxField {...applyChangesFieldProps} name='' label='' />
            <label
              htmlFor={applyChangesFieldProps.id}
              css={css`
                cursor: pointer;
              `}
            >
              {t('Apply changes to template')}
            </label>
          </NakedButton>
        )}
        {createTemplateAccess && (
          <Button
            variant='secondary'
            {...triggerProps}
            css={css`
              width: initial;
              @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
                display: none;
              }
            `}
            trackingId={`${trackingPrefix}-save-as-new-template-btn`}
          >
            {t('Save as New Template')}
          </Button>
        )}
        <ContinueButton
          onSave={(templateHtml, templateJson) =>
            onSave(
              { subject, templateHtml, templateId, templateJson, title: templateTitle ?? '' },
              applyChangesFieldProps.value
            )
          }
          openNewTemplateModal={triggerProps.onClick}
          trackingPrefix={trackingPrefix}
        />
      </div>
      {modalProps.show && (
        <NameYourTemplateModal
          fieldProps={nameFieldProps}
          modalProps={modalProps}
          onTemplateNameSave={(data) => {
            onTemplateNameSave(data);
            modalProps.onClose();
          }}
          subject={subject}
        />
      )}
    </section>
  );
};
