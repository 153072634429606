import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { EmptyData, Tooltip, TooltipData } from '../atoms';
import { useChartContext } from '../chart.provider';
import { EMPTY_STATE_MIN_HEIGHT } from '../constants';
import { adjustHexColor, defaultPercentageFormatter, showEmptyStateForGaugeChart } from '../helpers';
import { Appearance, GaugeChartProps } from './types';

const centerIconSize = 32;

const cellAppearance = (backFillColor: string, cornerRadius?: number, isHovered?: boolean) => {
  const hoverbackFillColor = isHovered ? adjustHexColor(backFillColor, 65) : backFillColor;

  return {
    cornerRadius: cornerRadius ?? 8,
    fill: hoverbackFillColor,
    stroke: hoverbackFillColor,
  };
};

const commonPieProps = ({ innerRadius = 65, outerRadius = 100, startAngle = 210, endAngle = -30 }: Appearance) => ({
  dataKey: 'value',
  endAngle,
  innerRadius,
  outerRadius,
  startAngle,
});

export const GaugeChart = memo(
  ({
    appearance = {},
    bottomContent,
    customCenterLabel,
    formatValue = defaultPercentageFormatter,
    iconConfig,
    onClick,
    value = 0,
  }: GaugeChartProps) => {
    const {
      backFillColor = theme.colors.neutral20,
      backFillStrokeWidth,
      borderRadius = 8,
      centerLabelFontSize = 28,
      collectiveTooltip,
      customTooltipData,
      customTooltipTitle,
      height = 240,
      hideLabel = !!customCenterLabel,
      hideTooltip,
      margin,
      width = '100%',
    } = appearance;

    const { colors, emptyStateConfig, labels, setPrimaryLegendsList } = useChartContext();
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [tooltipData, setTooltipData] = useState<Record<string, TooltipData[]>>({});
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const isClickable = !!onClick;

    const color = colors.value ?? theme.colors.neutral50;
    const formattedData = [
      { name: 'value', value },
      { name: 'backfill', value: 100 - value },
    ];

    const showEmptyState = useMemo(() => {
      return showEmptyStateForGaugeChart({
        ...emptyStateConfig,
        data: value,
      });
    }, [value, emptyStateConfig]);

    const handleMouseLeave = useCallback(() => {
      setIsHovered(false);
    }, []);

    useEffect(() => {
      setTooltipData({
        value: customTooltipData?.('value') || [
          {
            color,
            formattedValue: formatValue(value),
            id: 'value',
            label: labels?.value || 'value',
          },
        ],
      });

      setPrimaryLegendsList(Object.keys(tooltipData));
    }, [value, customTooltipData]);

    return (
      <div
        css={styles.mainWrapper}
        style={{
          height,
          minHeight: showEmptyState ? EMPTY_STATE_MIN_HEIGHT : height,
          width,
        }}
      >
        {!showEmptyState ? (
          <>
            <ResponsiveContainer height='100%' width='100%'>
              <PieChart margin={margin} onMouseLeave={handleMouseLeave}>
                {/* Background Pie */}
                <Pie
                  {...commonPieProps(appearance)}
                  data={[{ name: 'backfill', value: 100 }]}
                  fill={backFillColor}
                  isAnimationActive={false}
                >
                  <Cell
                    {...cellAppearance(backFillColor, borderRadius, isClickable && isHovered)}
                    strokeWidth={backFillStrokeWidth}
                  />
                </Pie>

                {/* Data Pie */}
                <Pie
                  {...commonPieProps(appearance)}
                  data={formattedData}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={(props, _i, event) => {
                    setIsHovered(collectiveTooltip || props.name === 'value');
                    setTooltipPosition({
                      x: event.clientX,
                      y: event.clientY,
                    });
                  }}
                  paddingAngle={isClickable ? 3 : 1}
                >
                  <Cell
                    {...cellAppearance(backFillColor, Math.min(formattedData[0]?.value || 0, borderRadius))}
                    cursor={isClickable ? 'pointer' : 'default'}
                    fill={color}
                    onClick={onClick}
                    role={isClickable ? 'button' : 'presentation'}
                    strokeWidth={isClickable ? 2 : 0}
                    stroke={colors.strokeColor || adjustHexColor(color, 20, true)}
                  />
                  <Cell
                    {...cellAppearance(
                      backFillColor,
                      Math.min(formattedData[1]?.value || 0, borderRadius),
                      isClickable && isHovered
                    )}
                    strokeWidth={backFillStrokeWidth}
                  />

                  {!!iconConfig?.name && (
                    <Label
                      position='center'
                      content={(props) => {
                        const { cx = 0, cy = 0 } = props.viewBox as { cx: number; cy: number };
                        const iconMidPoint = centerIconSize / 2;

                        // We need adjustment so that icon won't collide with the label
                        const yAdjustment = cy - iconMidPoint - centerLabelFontSize;

                        return (
                          <Icon
                            color={iconConfig.color}
                            height={centerIconSize}
                            name={iconConfig.name}
                            width={centerIconSize}
                            x={cx - iconMidPoint}
                            y={yAdjustment}
                          />
                        );
                      }}
                    />
                  )}
                  {!hideLabel && (
                    <Label
                      fill={theme.colors.neutral50}
                      fontSize={centerLabelFontSize}
                      fontWeight='bold'
                      position='center'
                      style={iconConfig?.name ? { transform: `translateY(${theme.spacing(1)})` } : {}}
                      value={formatValue(value)}
                    />
                  )}

                  {customCenterLabel?.(formatValue(value))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>

            {!!bottomContent && (
              <div
                css={styles.bottomContent}
                style={{
                  // It need to be calculated based on the height of the chart
                  // Even though the chart is about the half circle, it will still take the full height
                  // Hence adding a little bit of adjustment
                  marginTop:
                    typeof height === 'string'
                      ? `calc(${height} - ${theme.spacing(8)})`
                      : `calc(${height}px - ${theme.spacing(8)})`,
                }}
              >
                {bottomContent}
              </div>
            )}
          </>
        ) : (
          <EmptyData emptyStateConfig={emptyStateConfig} />
        )}

        {isHovered && !hideTooltip && (
          <Tooltip
            data={tooltipData.value || []}
            isClickable={isClickable}
            itemType='segment'
            name={customTooltipTitle || labels?.value || 'value'}
            xPos={tooltipPosition.x}
            yPos={tooltipPosition.y}
          />
        )}
      </div>
    );
  }
);

GaugeChart.displayName = 'GaugeChart';

const styles = {
  mainWrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    path.recharts-sector:focus {
      outline: none;
    }
  `,

  bottomContent: css`
    padding: ${theme.spacing(0, 3)};
    position: absolute;
  `,
};
