import { useState } from 'react';
import { DeviceSize } from '@frontend/device-size-field';
import { EmailPreviewModal } from '@frontend/email-preview-modal';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BaseFieldProps, Button, ContentLoader, useModalControl } from '@frontend/design-system';
import { useContentComposer } from '../../../../content-composer-provider';
import { useEmailSubjectHeader } from '../email-subject-header.context';

export const PreviewButton = (deviceSizeProps: BaseFieldProps<DeviceSize>) => {
  const { t } = useTranslation('email-composer');
  const { renderHtml, cancelRenderHtml } = useContentComposer();
  const { trackingPrefix } = useEmailSubjectHeader();
  const [previewHtml, setPreviewHtml] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);
  const previewModalProps = useModalControl();

  const generatePreview = async () => {
    setLoading(true);
    const html = await renderHtml('preview');
    setLoading(false);
    if (!html) return;
    previewModalProps.openModal();
    setPreviewHtml(html);
  };

  return (
    <>
      <Button
        variant='secondary'
        css={{ height: '100%', width: 'fit-content' }}
        onClick={generatePreview}
        trackingId={`${trackingPrefix}-preview-btn`}
      >
        {t('Preview')}
      </Button>
      <ContentLoader show={loading} message={t('Creating Preview...')} css={{ position: 'fixed' }}>
        <Button css={{ marginTop: theme.spacing(4) }} onClick={cancelRenderHtml}>
          {t('Cancel')}
        </Button>
      </ContentLoader>
      <EmailPreviewModal
        deviceSizeProps={deviceSizeProps}
        modalProps={previewModalProps.modalProps}
        previewHtml={previewHtml}
        setPreviewHtml={setPreviewHtml}
      />
    </>
  );
};
