import { useState } from 'react';
import { css } from '@emotion/react';
import { BulkMessagingApi } from '@frontend/api-bulk-messaging';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { Button, useModalControl, useAlert } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../../../constants';
import { useContentComposer } from '../../../../content-composer-provider';
import { useEmailSubjectHeader } from '../email-subject-header.context';
import { SendTestEmailModal } from './send-test-email-modal';

type Props = {
  subject: string;
};

export const SendTestEmailButton = ({ subject }: Props) => {
  const { t } = useTranslation('email-composer');
  const { renderHtml } = useContentComposer();
  const { trackingPrefix } = useEmailSubjectHeader();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const { selectedOrgId: orgId } = useAppScopeStore();

  const sendTestEmail = async (passedEmailAddress: string) => {
    if (!passedEmailAddress) {
      alert.error(t('Please enter an email address.'));
      return;
    }

    setLoading(true);

    try {
      const html = await renderHtml();
      if (!html) return;
      // TODO: does this need to be abstracted out?
      await BulkMessagingApi.sendTestEmail(orgId, subject ? subject : t('Test Email'), html, passedEmailAddress);
      alert.success(t('Test email sent!'));
      closeModal();
    } catch (error) {
      alert.error(t('Failed to send test email! Try again.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant='secondary'
        {...triggerProps}
        css={css`
          height: 100%;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
            display: none;
          }
        `}
        trackingId={`${trackingPrefix}-send-test-email-btn`}
      >
        {t('Send Test Email')}
      </Button>
      <Button
        variant='secondary'
        {...triggerProps}
        css={css`
          height: 100%;
          width: fit-content;
          @media (min-width: ${SIDEBAR_BREAKPOINTS.desktop + 1}px) {
            display: none;
          }
        `}
        trackingId={`${trackingPrefix}-send-test-email-btn`}
      >
        {t('Send Test')}
      </Button>
      <SendTestEmailModal
        hasSubject={!!subject}
        loading={loading}
        modalProps={modalProps}
        sendTestEmail={sendTestEmail}
      />
    </>
  );
};
