import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

type InboxTemplateImporterStore = {
  orgUserIdsShownImportUI: Record<string, { userId: string; timestamp: string }[]>;
  addUserIdToShownImportUI: (orgId: string, userId: string) => void;
  getUserHasBeenShownImportUI: (orgId: string, userId: string, timestampThreshold?: string) => boolean;
};

const DEFAULT_TIMESTAMP_THRESHOLD_OFFSET = 1000 * 60 * 60 * 24 * 14; // 14 days

export const useInboxTemplateImporterStore = createStoreWithPersistAndSubscribe<InboxTemplateImporterStore>(
  (set, get) => ({
    orgUserIdsShownImportUI: {},
    addUserIdToShownImportUI: (orgId, userId) => {
      set((state) => {
        const existingOrgUserIds = [...(state.orgUserIdsShownImportUI[orgId] ?? [])];
        const hasUserId = existingOrgUserIds.some((user) => user.userId === userId);
        const newOrgUserIds = hasUserId
          ? existingOrgUserIds
          : [...existingOrgUserIds, { timestamp: new Date().toISOString(), userId }];

        return {
          ...state,
          orgUserIdsShownImportUI: {
            ...state.orgUserIdsShownImportUI,
            [orgId]: newOrgUserIds,
          },
        };
      });
    },
    getUserHasBeenShownImportUI: (
      orgId: string,
      userId: string,
      timestampThreshold = new Date(new Date().getTime() - DEFAULT_TIMESTAMP_THRESHOLD_OFFSET).toISOString()
    ) => {
      const orgList = get().orgUserIdsShownImportUI[orgId] ?? [];
      const userEntry = orgList.find((user) => user.userId === userId);
      return !!userEntry && userEntry.timestamp >= timestampThreshold;
    },
  }),
  { name: 'InboxTemplateImporterStore', version: 0 },
  { name: 'InboxTemplateImporterStore', trace: true }
);

export const useInboxTemplateImporterShallowStore = createShallowStore(useInboxTemplateImporterStore);
