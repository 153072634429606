import { CustomCodeSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';

export type OldCustomCodeSectionProps = {
  type: string;
  code?: string;
};

export const migrateCustomCodeSection = (oldSection: OldCustomCodeSectionProps): CustomCodeSection => {
  return {
    sectionType: 'CustomCodeSection',
    code: oldSection.code,
  };
};
