import { Fragment, memo, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { EmptyData, ScrollControl } from '../atoms';
import { useChartContext } from '../chart.provider';
import { EMPTY_STATE_MIN_HEIGHT } from '../constants';
import { defaultFormatter, showEmptyStateForCategoryBarChart } from '../helpers';
import { BarChart } from './bar-chart';
import { CategoryBarChartProps, CustomColumn, FormatCategorizedBarGroups } from './category-bar-chart-types';
import { ColumnHeader } from './column-header';
import { Groups } from './groups';
import { ShowMore } from './show-more';
import { BarChartData, CategoryBarChartData } from './types';

const mainGroupWidth = 180;
const mainGroupRightMargin = 30;
const snGroupWidth = 30;
const snGroupRightMargin = 16;
const actionsGroupWidth = 40;
const minChartBGMargin = 2;
const maxChartBGMargin = 16;

const sumOfObjectValues = (obj: Record<string, number>) => Object.values(obj).reduce((acc, value) => acc + value, 0);

const transformData = (data: CategoryBarChartData) => {
  const transformedData: Record<string, Record<string, number>> = {};

  Object.entries(data).forEach(([location, categories]) => {
    Object.entries(categories).forEach(([category, value]) => {
      if (!transformedData[category]) {
        transformedData[category] = {};
      }
      transformedData[category][location] = value ?? 0;
    });
  });

  return transformedData;
};

const getSorted = (a: number | string, b: number | string, sortedAsc?: boolean) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return sortedAsc ? a - b : b - a;
  } else if (typeof a === 'string' && typeof b === 'string') {
    return sortedAsc ? a.localeCompare(b) : b.localeCompare(a);
  } else {
    return 0;
  }
};

export const CategoryBarChart = memo(
  ({
    appearance = {},
    children,
    customColumns,
    customColumnValueRenderer,
    data,
    highestValueConfig,
    markers,
    onClick,
    onGroupClick,
    sortConfig,
    trackingIdBase,
  }: PropsWithChildren<CategoryBarChartProps>) => {
    const { customTooltipData, sortableStack, stackTitle, ...restAppearance } = appearance;
    const {
      alternateCategoryBackgroundColor,
      chartsGap = 40,
      customCategoryNameRenderer,
      defaultRowsCount = 4,
      formatters,
      maxValues,
      mode = 'grouped',
      xAxisTickFormatters,
    } = restAppearance;

    const { t } = useTranslation('analytics');
    const scrollRef = useRef<HTMLDivElement>(null);
    const { emptyStateConfig } = useChartContext();
    const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(false);
    const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(false);
    const [sortedBy, setSortedBy] = useState<string | undefined>(sortConfig?.sortedBy);
    const [sortedAsc, setSortedAsc] = useState<boolean | undefined>(sortConfig?.sortedAsc);
    const [showMore, setShowMore] = useState<boolean | undefined>(false);

    const margin = { top: 0, right: 0, bottom: 0, left: 0, ...restAppearance?.margin };
    const hasGroupClick = typeof onGroupClick === 'function';
    const groupsContainerWidth = margin.left || mainGroupWidth;
    const chartBGMargin = alternateCategoryBackgroundColor ? maxChartBGMargin : minChartBGMargin;
    const nonChartAreaWidth =
      snGroupWidth +
      snGroupRightMargin +
      groupsContainerWidth +
      mainGroupRightMargin +
      (hasGroupClick ? actionsGroupWidth : 0);

    const showEmptyState = useMemo(() => {
      return showEmptyStateForCategoryBarChart({
        ...emptyStateConfig,
        data,
        markers,
      });
    }, [data, emptyStateConfig, markers]);

    const maxValue = useMemo(() => {
      if (maxValues) {
        return;
      }

      const values = Object.values(data).reduce((acc, item) => {
        // If mode is stacked, use the sum of all values else use the individual values
        const itemValues =
          mode === 'stacked'
            ? [sumOfObjectValues(item)]
            : Object.values(item).map((value) => (typeof value === 'undefined' ? 0 : value));
        return [...acc, ...itemValues];
      }, [] as number[]);

      // Ensure maxValue is at least 4 to avoid decimal points in the x-axis
      return Math.ceil(Math.max(...values) / 4) * 4;
    }, [data, maxValues, mode]);

    const formatCategorizedBarGroups = useMemo((): FormatCategorizedBarGroups => {
      const formattedData: Record<string, BarChartData> = {};
      let sortedGroupNames: string[] = [];
      let hasCustomColumnSortedBy = false;

      // If custom columns are provided and sortedBy belongs to custom columns, we should prioritize the custom columns
      if (customColumns?.length && sortedBy) {
        const customColumnsHeaderIds = customColumns.reduce<Record<string, CustomColumn['data']>>(
          (acc, { columnHeaderId, data }) => ({ ...acc, [columnHeaderId]: data }),
          {}
        );
        hasCustomColumnSortedBy = customColumnsHeaderIds[sortedBy] !== undefined;
      }

      const getSortedObject = (dataToConsiderSorting: Record<string, number | string> | null) => {
        return dataToConsiderSorting
          ? Object.fromEntries(
              Object.entries(dataToConsiderSorting).sort(([, a], [, b]) => getSorted(a, b, sortedAsc))
            ) || null
          : null;
      };

      const getSortedGroupNames = (sortedObject: Record<string, number | string> | CategoryBarChartData) => {
        let names = Object.keys(sortedObject) || [];
        if (sortedBy === 'groupNameHeader') {
          names = names.sort((a, b) => (sortedAsc ? a.localeCompare(b) : b.localeCompare(a)));
        }
        return names;
      };

      if (mode === 'stacked') {
        const dataToConsiderSorting = hasCustomColumnSortedBy
          ? customColumns?.find(({ columnHeaderId }) => columnHeaderId === sortedBy)?.data ?? null
          : null;

        const sortedObject = getSortedObject(dataToConsiderSorting) || data;
        sortedGroupNames = getSortedGroupNames(sortedObject);

        const groups = Object.entries(data)
          .map(([name, values]) => ({
            name,
            values,
          }))
          .sort((a, b) => {
            if (sortedBy === stackTitle) {
              // As it is stacked chart, we need to sort it based on the sum of all values
              const sumOFA = sumOfObjectValues(a.values);
              const sumOFB = sumOfObjectValues(b.values);
              return sortedAsc ? sumOFA - sumOFB : sumOFB - sumOFA;
            }

            return sortedGroupNames.indexOf(a.name) - sortedGroupNames.indexOf(b.name);
          })
          .slice(0, showMore ? undefined : defaultRowsCount);

        if (sortedBy === stackTitle) {
          sortedGroupNames = groups.map(({ name }) => name);
        }

        formattedData['stacked'] = {
          groups,
          markers: Array.isArray(markers)
            ? markers
            : Object.values(markers || {})
                .flat()
                .filter((value) => !!value),
        };
      } else {
        const transformedData = transformData(data);

        const dataToConsiderSorting = sortedBy
          ? hasCustomColumnSortedBy
            ? customColumns?.find(({ columnHeaderId }) => columnHeaderId === sortedBy)?.data ?? null
            : transformedData[sortedBy] ?? null
          : null;

        const sortedObject = getSortedObject(dataToConsiderSorting) || Object.values(transformedData || {})[0] || {};
        sortedGroupNames = getSortedGroupNames(sortedObject);

        Object.entries(transformedData).forEach(([groupName, locationsValues]) => {
          const groups = Object.entries(locationsValues)
            .map(([location, value]) => ({
              name: location,
              values: {
                [groupName]: value || 0,
              },
            }))
            .sort((a, b) => {
              return sortedGroupNames.indexOf(a.name) - sortedGroupNames.indexOf(b.name);
            })
            .slice(0, showMore ? undefined : defaultRowsCount);

          formattedData[groupName] = {
            groups,
            markers: Array.isArray(markers) ? markers : markers?.[groupName],
          };
        });
      }

      return {
        chartGroups: formattedData,
        sortedGroupNames,
      };
    }, [JSON.stringify(customColumns), data, defaultRowsCount, markers, mode, showMore, sortedAsc, sortedBy]);

    const groupNames = Object.values(formatCategorizedBarGroups.chartGroups)[0]?.groups.map(({ name }) => name) || [];
    const groupsLength = groupNames.length;

    const handleControlledScroll = useCallback((direction: 'left' | 'right' | 'reset') => {
      if (!scrollRef.current) {
        return;
      }

      const scrollWidth = scrollRef.current.scrollWidth;
      const clientWidth = scrollRef.current.clientWidth;

      if (direction === 'reset') {
        scrollRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        });

        const scrollable = scrollWidth > clientWidth;
        setShowLeftScrollButton(false);
        setShowRightScrollButton(scrollable);
        return;
      }

      const keepInViewAdjustment = 50;
      let scrollLeft = 0;

      if (direction === 'left') {
        scrollLeft = Math.max(scrollRef.current.scrollLeft - clientWidth + keepInViewAdjustment, 0);
        setShowLeftScrollButton(scrollLeft > 0);
        setShowRightScrollButton(true);
      } else {
        scrollLeft = scrollRef.current.scrollLeft + clientWidth - keepInViewAdjustment;
        setShowLeftScrollButton(true);
        setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
      }

      scrollRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }, []);

    const onSortClick = useCallback(
      (categoryId: string, sortedAsc?: boolean) => {
        setSortedBy(categoryId);

        // Toggle the sort direction if the same category is clicked
        if (sortedBy === categoryId) {
          setSortedAsc((prev) => !prev);
        } else {
          setSortedAsc(sortedAsc ?? true);
        }
      },
      [sortedBy, sortedAsc]
    );

    const highestValueGroup = useMemo(() => {
      if (mode === 'grouped' && !highestValueConfig?.categoryId) {
        return;
      }

      // Read higest value of the given key against the group name
      // A sum of all values is considered when mode is stacked and for such case categoryId is not required
      return Object.entries(data).reduce(
        (acc, [groupName, groupData]) => {
          const value =
            mode === 'grouped' && highestValueConfig?.categoryId
              ? groupData[highestValueConfig.categoryId] || 0
              : sumOfObjectValues(groupData);

          return value > acc.value ? { groupName, value } : acc;
        },
        {
          groupName: '',
          value: 0,
        }
      );
    }, [data, highestValueConfig, mode]);

    useEffect(() => {
      // Debounce the scroll event to avoid performance issues
      let timeout: ReturnType<typeof setTimeout>;

      const handleScroll = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          if (!scrollRef.current) {
            return;
          }

          const scrollWidth = scrollRef.current.scrollWidth;
          const clientWidth = scrollRef.current.clientWidth;
          const scrollLeft = scrollRef.current.scrollLeft;

          setShowLeftScrollButton(scrollLeft > 0);
          setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
        }, 100);
      };

      if (scrollRef.current) {
        scrollRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollRef.current) {
          scrollRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, []);

    useEffect(() => {
      if (!scrollRef.current) {
        return;
      }
      handleControlledScroll('reset');
    }, [formatCategorizedBarGroups.chartGroups]);

    return (
      <>
        <div
          css={styles.mainWrapper}
          style={{
            height: showEmptyState ? EMPTY_STATE_MIN_HEIGHT : 'auto',
          }}
        >
          {showEmptyState ? (
            <EmptyData emptyStateConfig={emptyStateConfig} />
          ) : (
            <>
              <div
                css={styles.groupsWrapper}
                style={{
                  marginRight: snGroupRightMargin,
                  width: snGroupWidth,
                }}
              >
                <ColumnHeader categoryId='sn' />
                <div css={styles.actionsWrapper}>
                  {groupNames.map((_groupName, index) => (
                    <Text color='subdued' key={index} size='medium' weight='bold'>
                      {index + 1}
                    </Text>
                  ))}
                </div>
              </div>
              <div css={styles.groupsWrapper}>
                <ColumnHeader
                  canUserSort={sortConfig?.allowUserToSort}
                  categoryId='groupNameHeader'
                  customCategoryNameRenderer={customCategoryNameRenderer}
                  onClick={onSortClick}
                  sortedAsc={sortedBy === 'groupNameHeader' ? sortedAsc : undefined}
                  sortedBy={sortedBy === 'groupNameHeader'}
                  trackingIdBase={trackingIdBase}
                />
                <Groups
                  groupNames={groupNames}
                  highestValueConfig={highestValueConfig}
                  highestValueGroup={highestValueGroup?.groupName}
                  style={{
                    marginRight: mainGroupRightMargin,
                    width: groupsContainerWidth,
                  }}
                />
              </div>
              <div
                css={styles.scrollableViewWrapper}
                style={{
                  width: `calc(100% - ${nonChartAreaWidth}px)`,
                }}
              >
                <div css={styles.scrollableView} ref={scrollRef} style={{ gap: chartsGap }}>
                  {customColumns?.map(({ columnHeaderId, columnHeaderLabel, data }) => {
                    return (
                      <div css={styles.groupsWrapper} key={columnHeaderId}>
                        <ColumnHeader
                          canUserSort={sortConfig?.allowUserToSort}
                          categoryId={columnHeaderId}
                          columnHeaderLabel={columnHeaderLabel}
                          customCategoryNameRenderer={customCategoryNameRenderer}
                          onClick={onSortClick}
                          sortedAsc={sortedBy === columnHeaderId ? sortedAsc : undefined}
                          sortedBy={sortedBy === columnHeaderId}
                          trackingIdBase={trackingIdBase}
                        />
                        <div css={styles.actionsWrapper}>
                          {formatCategorizedBarGroups.sortedGroupNames.map((name) => {
                            const formattedValue =
                              typeof data[name] === 'string'
                                ? data[name]
                                : formatters
                                ? typeof formatters === 'function'
                                  ? formatters(data[name])
                                  : formatters[columnHeaderId]?.(data[name]) ?? defaultFormatter(data[name])
                                : defaultFormatter(data[name]);

                            return (
                              <Fragment key={name}>
                                {typeof customColumnValueRenderer === 'function' ? (
                                  <div>
                                    {customColumnValueRenderer({
                                      columnHeaderId,
                                      groupName: name,
                                      value: formattedValue,
                                    })}
                                  </div>
                                ) : (
                                  <Text size='medium'>{formattedValue}</Text>
                                )}
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  {Object.entries(formatCategorizedBarGroups.chartGroups).map(([categoryId, chartData]) => {
                    const categortIdMod =
                      mode === 'stacked' ? stackTitle ?? (sortableStack ? categoryId : 'placeholder') : categoryId;

                    return (
                      <div
                        css={styles.chartWrapper(alternateCategoryBackgroundColor)}
                        key={JSON.stringify(chartData)}
                        style={{
                          flexBasis: `${
                            100 / Math.min(Object.keys(formatCategorizedBarGroups.chartGroups).length, 4)
                          }%`,
                        }}
                      >
                        <ColumnHeader
                          canUserSort={mode === 'stacked' ? sortableStack : sortConfig?.allowUserToSort}
                          categoryId={categortIdMod}
                          customCategoryNameRenderer={customCategoryNameRenderer}
                          onClick={onSortClick}
                          sortedAsc={sortedBy === categortIdMod ? sortedAsc : undefined}
                          sortedBy={sortedBy === categortIdMod}
                          trackingIdBase={trackingIdBase}
                        />
                        <div className='chart'>
                          <BarChart
                            appearance={{
                              customXAxisTickFormat:
                                xAxisTickFormatters?.[categoryId] ||
                                (formatters
                                  ? (value) =>
                                      typeof formatters === 'function'
                                        ? formatters(value)
                                        : formatters[categoryId]?.(value)
                                  : defaultFormatter),
                              groupsGap: 24,
                              layout: 'vertical',
                              maxValue: maxValues?.[categoryId] || maxValue,
                              ...restAppearance,

                              // The following values are not supposed to be overriden, hence keep these after the spread operator
                              ...(customTooltipData
                                ? {
                                    customTooltipData: (groupId: string) => customTooltipData(groupId, categoryId),
                                  }
                                : {}),
                              margin: {
                                top: groupsLength === 1 ? 8 : margin.top || 0,
                                right: margin.right || chartBGMargin,
                                bottom: margin.bottom || 0,
                                left: chartBGMargin,
                              },
                              showXAxis: true,
                            }}
                            customLegendsIds={
                              mode === 'stacked' ? undefined : Object.keys(formatCategorizedBarGroups.chartGroups)
                            }
                            data={chartData}
                            formatValue={
                              formatters
                                ? typeof formatters === 'function'
                                  ? formatters
                                  : formatters[categoryId]
                                : defaultFormatter
                            }
                            isUsedInCategoryBarChart
                            key={JSON.stringify(chartData)}
                            onClick={onClick}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <ScrollControl
                  css={styles.scrollButton}
                  direction='left'
                  isVisible={showLeftScrollButton}
                  onClick={() => handleControlledScroll('left')}
                />

                <ScrollControl
                  css={styles.scrollButton}
                  direction='right'
                  isVisible={showRightScrollButton}
                  onClick={() => handleControlledScroll('right')}
                />
              </div>
              {hasGroupClick && (
                <div css={styles.groupsWrapper} style={{ width: actionsGroupWidth }}>
                  <ColumnHeader categoryId='placeholder' />
                  <div css={styles.actionsWrapper} style={{ marginLeft: theme.spacing(1) }}>
                    {Object.entries(data).map(([groupName, groupData], index) => {
                      return (
                        <Button
                          iconName='caret-right-small'
                          key={groupName}
                          label={t('More Details')}
                          onClick={() => {
                            onGroupClick?.({ groupName, groupData });
                          }}
                          size='small'
                          trackingId={`${trackingIdBase}-group-button-${index + 1}`}
                          variant='secondary'
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {children}
        {Object.keys(data || {}).length > defaultRowsCount && <ShowMore onClick={setShowMore} value={showMore} />}
      </>
    );
  }
);

CategoryBarChart.displayName = 'CategoryBarChart';

const styles = {
  mainWrapper: css`
    display: flex;
    flex-wrap: nowrap;
    position: relative;
  `,

  groupsWrapper: css`
    display: flex;
    flex-direction: column;
  `,

  scrollableViewWrapper: css`
    position: relative;
  `,

  scrollableView: css`
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    overflow: auto;
  `,

  chartWrapper: (alternateBgColor?: string) => css`
    min-width: 220px;

    &:nth-of-type(odd) {
      > .chart {
        background-color: ${alternateBgColor};
      }
    }
  `,

  scrollButton: css`
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;

    &.left-scroll {
      left: ${theme.spacing(-0.5)};
    }

    &.right-scroll {
      right: ${theme.spacing(-1)};
    }
  `,

  actionsWrapper: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    margin-bottom: ${theme.spacing(4)};
    transition: margin-top 0.2s;
  `,
};
