import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { ImageSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { PercentCrop } from 'react-image-crop';
import { Corners } from '../../types';
import { convertMediaToImage } from '../convert-media-to-image';
import { formatFrame } from './format-frame';

type ImageCount = '1' | '2' | '3';
export type OldImageSectionProps = {
  type: string;
  corners?: Corners;
  count?: ImageCount;
  crops?: PercentCrop[];
  frameColor?: string;
  height?: string;
  imageSpacing?: string;
  images?: (Media | undefined)[];
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
};

export const migrateImageSection = (oldSection: OldImageSectionProps): ImageSection => {
  return {
    sectionType: 'ImageSection',
    corners: oldSection.corners,
    frame: formatFrame(oldSection),
    height: oldSection.height,
    imageCount: parseInt(oldSection.count || '1'),
    imageSpacing: parseInt(oldSection.imageSpacing || '0'),
    images: oldSection.images?.map((image: Media | undefined, index: number) =>
      convertMediaToImage(image, oldSection.crops?.[index])
    ),
  };
};
