import { BuilderTypes } from '@frontend/forms-builder-engine';
import { DragAndDrop } from '../../../constants';

const draggablePrefixes = {
  primaryField: 'primary',
  paymentsField: 'payments',
  otherField: 'elements',
  fieldInSection: 'field-in-section',
};

/**
 * @param fieldKey - The key of the primary field.
 * @returns The draggable ID for the primary field.
 */
export function getPrimaryFieldDraggableId(
  fieldKey: BuilderTypes.BuilderFormElementTypes.PrimaryFields.PrimaryFieldKey
): string {
  return [draggablePrefixes.primaryField, fieldKey].join(DragAndDrop.SEPARATOR);
}

/**
 * @param fieldKey - The key of the payments field.
 * @returns The draggable ID for the payments field.
 */
export function getPaymentsFieldDraggableId(
  fieldKey: BuilderTypes.BuilderFormElementTypes.PaymentsFields.ParsedFieldKey
): string {
  return [draggablePrefixes.paymentsField, fieldKey].join(DragAndDrop.SEPARATOR);
}

/**
 * @param fieldKey - The key of the other field.
 * @returns The draggable ID for the other field.
 */
export function getOtherFieldDraggableId(
  fieldKey: BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFieldsKey
): string {
  return [draggablePrefixes.otherField, fieldKey].join(DragAndDrop.SEPARATOR);
}

type FieldInfoFromDraggableId =
  | {
      isPrimaryField: true;
      isPaymentsField: false;
      fieldKey: BuilderTypes.BuilderFormElementTypes.PrimaryFields.PrimaryFieldKey;
    }
  | {
      isPrimaryField: false;
      isPaymentsField: false;
      fieldKey: BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFieldsKey;
    }
  | {
      isPrimaryField: false;
      isPaymentsField: true;
      fieldKey: BuilderTypes.BuilderFormElementTypes.PaymentsFields.ParsedFieldKey;
    };

/**
 * @param draggableId - The draggable ID.
 * @returns The field key and whether it is a primary field or not.
 */
export function getFieldInfoFromDraggableId(draggableId: string): FieldInfoFromDraggableId {
  const [fieldType, fieldKey] = draggableId.split(DragAndDrop.SEPARATOR);

  if (fieldType === draggablePrefixes.primaryField) {
    return {
      isPrimaryField: true,
      isPaymentsField: false,
      fieldKey: fieldKey as BuilderTypes.BuilderFormElementTypes.PrimaryFields.PrimaryFieldKey,
    };
  }

  if (fieldType === draggablePrefixes.paymentsField) {
    return {
      isPrimaryField: false,
      isPaymentsField: true,
      fieldKey: fieldKey as BuilderTypes.BuilderFormElementTypes.PaymentsFields.ParsedFieldKey,
    };
  }

  return {
    isPrimaryField: false,
    isPaymentsField: false,
    fieldKey: fieldKey as BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFieldsKey,
  };
}

/**
 * @param fieldId - The ID of the field.
 * @returns The draggable ID for the field in a section.
 */
export function getDraggableId(fieldId: string): string {
  return [draggablePrefixes.fieldInSection, fieldId].join(DragAndDrop.SEPARATOR);
}

/**
 * This should only be used for fields in a section.
 * @param draggableId - The draggable ID.
 * @returns The field ID.
 */
export function getFieldId(draggableId: string): string {
  return draggableId.split(DragAndDrop.SEPARATOR)[1];
}
