import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, DynamicFieldIconSmall, Text, usePopoverDialog } from '@frontend/design-system';
import { DynamicFieldPickerPopover } from './dynamic-field-picker-popover';

export const DynamicFieldButton = (props: ReturnType<typeof usePopoverDialog>) => {
  const { t } = useTranslation('email-composer');
  return (
    <>
      <Button
        variant='secondary'
        {...props.getTriggerProps()}
        css={{
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0.5, 1),
          width: theme.spacing(15.5),
          columnGap: theme.spacing(1),
        }}
      >
        <DynamicFieldIconSmall />
        <Text
          as='span'
          size='small'
          css={{
            width: 'fit-content',
          }}
        >
          {t('Dynamic Field')}
        </Text>
      </Button>
      <DynamicFieldPickerPopover {...props} />
    </>
  );
};
