export const formatFrame = ({
  frameAlignment,
  frameColor,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
}: any) => ({
  alignment: frameAlignment,
  backgroundColor: frameColor,
  paddingBottom: paddingBottom,
  paddingTop: paddingTop,
  paddingLeft: paddingLeft,
  paddingRight: paddingRight,
});
