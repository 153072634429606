import { useState } from 'react';
import { css } from '@emotion/react';
// TODO: Leaving here for a reference and will revisit the one styling logic for hiding the subject line on mobile when there is an element selected
// import { ROOT_NODE, useEditor } from '@craftjs/core';
import { DeviceSize, DeviceSizeField } from '@frontend/device-size-field';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BaseFieldProps, TextField, usePopoverDialog } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../../constants';
import { AddSectionButton } from './add-section/add-section-button';
import { DynamicFieldButton } from './dynamic-field-picker/dynamic-field-button';
import { EmailAssistantButton } from './email-assistant/email-assistant-button';
import { PreviewButton } from './email-preview/preview-button';
import { EmailSubjectHeaderContext } from './email-subject-header.context';
import { EmojiButton } from './emoji-picker/emoji-button';
import { SendTestEmailButton } from './send-test-email/send-test-email-button';

const SUBJECT_FIELD_NAME = 'subject';

type Props = {
  deviceSizeProps: BaseFieldProps<DeviceSize>;
  subjectProps?: BaseFieldProps<string>;
  trackingPrefix: string;
};

export const EmailSubjectHeader = ({ deviceSizeProps, subjectProps, trackingPrefix }: Props) => {
  const { t } = useTranslation('email-composer');
  const [subject, setSubject] = useState(subjectProps?.value ?? '');
  // TODO: Leaving here for a reference and will revisit the one styling logic for hiding the subject line on mobile when there is an element selected
  // const { actions, selectedId } = useEditor((state) => {
  //   const [currentNodeId] = state.events.selected;
  //   return {
  //     selectedId: currentNodeId,
  //   };
  // });

  // DYNAMIC FIELD LOGIC
  const dynamicFieldPopoverDialogProps = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -170,
      y: 0,
    },
  });

  // EMOJI PICKER LOGIC
  const emojiPopoverDialogProps = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -252,
      y: 0,
    },
  });

  const deviceProps = { ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize };

  return (
    <EmailSubjectHeaderContext.Provider value={{ setSubject, subject, subjectProps, trackingPrefix }}>
      <section
        css={css`
          grid-area: header;
          display: grid;
          grid-template-columns: 1fr auto auto;
          align-items: center;
          padding: ${theme.spacing(2)};
          column-gap: ${theme.spacing(1)};
          border-bottom: solid 1px ${theme.colors.neutral20};
          max-width: 100vw;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            grid-template-areas: 'subject' 'bottom';
            grid-template-columns: auto;
            align-items: unset;
            row-gap: ${theme.spacing(2)};
            // TODO: cannot use the editor state here, re-add the escape JSX interpolation
            /* display: selectedId && selectedId !== ROOT_NODE ? 'none' : 'grid'; */

            transition: display 0.3s ease-in-out;
          }
        `}
      >
        <TextField
          label={t('Subject')}
          name={SUBJECT_FIELD_NAME}
          {...(subjectProps as BaseFieldProps<string>)}
          onChange={(e) => {
            // @ts-ignore DS TextField onChange event type
            const value = e.target.value;
            setSubject(value);
            subjectProps?.onChange({ ...subjectProps, value, name: 'subject' });
          }}
          css={css`
            padding-right: ${theme.spacing(1)};
            .subject-end-adornment {
              display: ${dynamicFieldPopoverDialogProps.isOpen || emojiPopoverDialogProps.isOpen ? 'flex' : 'none'};
            }
            &:focus-within {
              .subject-end-adornment {
                display: flex;
              }
            }
            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              grid-area: subject;
            }
          `}
          endAdornment={
            <div
              className='subject-end-adornment'
              css={{
                alignItems: 'center',
                columnGap: theme.spacing(1),
                button: {
                  height: 'auto',
                },
              }}
            >
              <DynamicFieldButton {...dynamicFieldPopoverDialogProps} />
              <EmojiButton {...emojiPopoverDialogProps} />
              <EmailAssistantButton />
            </div>
          }
        />
        {/* since this is being moved out to the consumer component this casting will not be necessary */}
        <DeviceSizeField {...(deviceProps as BaseFieldProps<DeviceSize>)} />
        <div
          css={css`
            height: 100%;
            max-height: 40px;
            display: flex;
            column-gap: ${theme.spacing(1)};
            align-items: center;

            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              grid-area: bottom;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              columnGap: theme.spacing(1),
              height: '100%',
            }}
          >
            <AddSectionButton />
            <PreviewButton {...(deviceProps as BaseFieldProps<DeviceSize>)} />
            <SendTestEmailButton subject={subjectProps?.value ?? ''} />
          </div>
          {/* TODO: connect the undo and redo logic here: <HistoryButtons /> */}
        </div>
      </section>
    </EmailSubjectHeaderContext.Provider>
  );
};
