import { useEffect, useState } from 'react';
import { EditableText } from '@frontend/editable-text';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BaseFieldProps, Modal, ModalControlModalProps } from '@frontend/design-system';
import { useContentComposer } from '../../../content-composer-provider';
import { OnTemplateNameChange } from './types';

type Props = {
  fieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  modalProps: ModalControlModalProps;
  onTemplateNameSave: (data: OnTemplateNameChange) => void;
  subject: string;
};

export const NameYourTemplateModal = ({ fieldProps, modalProps, onTemplateNameSave, subject }: Props) => {
  const { t } = useTranslation('email-composer');
  const { selectedOrgId: orgId, selectedLocationIds: locationIds } = useAppScopeStore();
  const [templateHtml, setTemplateHtml] = useState('');
  const [templateJson, setTemplateJson] = useState('');
  const { getValue, renderHtml } = useContentComposer();

  const generateHtml = async () => {
    const html = await renderHtml();
    if (!html) return;
    setTemplateHtml(html);
  };

  const generateJson = () => {
    const document = getValue();
    setTemplateJson(JSON.stringify(document));
  };

  useEffect(() => {
    generateJson();
    generateHtml();
  }, []);

  return (
    <Modal {...modalProps} css={{ minWidth: 'min(600px, 95%)' }}>
      <Modal.Header>{t('Name Your New Template')}</Modal.Header>
      <Modal.Body>
        <EditableText fieldProps={fieldProps} helperText={t('Template Title')} />
      </Modal.Body>
      <Modal.Actions
        secondaryLabel={t('Cancel')}
        primaryLabel={t('Confirm & Save Template')}
        onPrimaryClick={() => {
          onTemplateNameSave({
            title: fieldProps.value,
            subject,
            templateHtml,
            templateJson,
            orgId,
            locationIds,
          });
        }}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};
