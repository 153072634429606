import { EmailAssistantModal } from '@frontend/email-assistant-modal';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useModalControl } from '@frontend/design-system';
import { emailAssistantGradientStyling } from '../../styles';
import { useContentComposer } from '../content-composer-provider';
import { TextSection } from '../sections';
import { Divider, ToolboxButton } from '../sidebar';

export const AssistantTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection, promptProps } = useContentComposer();
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <ToolboxButton
        icon='aiassistant'
        title={t('Weave Assistant')}
        subtitle={t('Automatically generate content for your email campaign.')}
        onClick={triggerProps.onClick}
        css={emailAssistantGradientStyling}
        trackingId={`${BulkEmailPrefixes.Composer}-assisant-tool-btn`}
      />
      <Divider />
      <EmailAssistantModal
        modalProps={modalProps}
        onComplete={(value: string) => insertSection(<TextSection text={value} />)}
        promptProps={promptProps}
        type='text'
      />
    </>
  );
};
