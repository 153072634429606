import { FooterSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { FrameAlignment, FooterLinkType } from '../../types';
import { formatFrame } from './format-frame';
import { formatLink } from './format-link';

type FooterLink = {
  type: FooterLinkType;
  link: string;
  text: string;
};

export type OldFooterSectionProps = {
  type: string;
  bold?: boolean;
  fontType?: string;
  frameAlignment?: FrameAlignment;
  frameColor?: string;
  italic?: boolean;
  links?: FooterLink[];
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  textColor?: string;
  textSize?: number;
};

export const migrateFooterSection = (oldSection: OldFooterSectionProps): FooterSection => {
  return {
    sectionType: 'FooterSection',
    bold: oldSection.bold,
    fontType: oldSection.fontType,
    frame: formatFrame(oldSection),
    italic: oldSection.italic,
    links: oldSection.links?.map((link) => formatLink({ ...link, linkType: link.type })),
    textColor: oldSection.textColor,
    textSize: oldSection.textSize,
  };
};
