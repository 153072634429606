import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, MultiActionButton } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../../constants';
import { useContentComposer } from '../../../content-composer-provider';

type Props = {
  onSave: (html: string, json: string) => void;
  openNewTemplateModal: () => void;
  trackingPrefix: string;
};

export const ContinueButton = ({ onSave, openNewTemplateModal, trackingPrefix }: Props) => {
  const { t } = useTranslation('email-composer');
  const { renderHtml, cancelRenderHtml, getValue } = useContentComposer();
  const [loading, setLoading] = useState(false);

  const saveEmail = async () => {
    setLoading(true);
    const templateHtml = await renderHtml();

    if (!templateHtml) {
      setLoading(false);
      return;
    }

    const document = getValue();
    const templateJson = JSON.stringify(document);

    onSave(templateHtml, templateJson);
    setLoading(false);
  };

  return (
    <>
      <Button
        onClick={saveEmail}
        css={css`
          width: initial;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            display: none;
          }
        `}
        trackingId={`${trackingPrefix}-save-email-btn`}
      >
        {t('Continue')}
      </Button>
      <MultiActionButton
        label={t('Continue')}
        menuPlacement='top-start'
        onClick={saveEmail}
        trackingId={`${trackingPrefix}-save-email-btn`}
        actions={[
          {
            label: t('Save as New Template'),
            onClick: openNewTemplateModal,
            trackingId: `${trackingPrefix}-save-as-new-template-btn`,
          },
        ]}
        css={css`
          display: none;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            display: flex;
            button {
              height: 32px;
              font-size: 14px;
              width: fit-content;
              &:last-of-type {
                padding: ${theme.spacing(1)};
              }
            }
          }
        `}
      />
      <ContentLoader show={loading} message={t('Saving Email...')} css={{ position: 'fixed' }}>
        <Button css={{ marginTop: theme.spacing(4) }} onClick={cancelRenderHtml}>
          {t('Cancel')}
        </Button>
      </ContentLoader>
    </>
  );
};
