import { Editor } from '@craftjs/core';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { theme } from '@frontend/theme';
import { BaseFieldProps, DynamicFieldAttributes } from '@frontend/design-system';
import { ComposerDocument, ComposerParts, ComposerMode, DeviceSize } from '../types';
import { ContentComposerProvider } from './content-composer-provider';
import { Container, Designer } from './editor';

type Props = {
  children?: React.ReactNode;
  composerMode: ComposerMode;
  deviceSizeProps?: BaseFieldProps<DeviceSize>;
  initialValue?: ComposerDocument;
  locationIds: string[];
  parts: ComposerParts;
  renderMode?: string;
  subjectDynamicFields?: DynamicFieldAttributes[];
  templateTypeSlug: TemplateType_Slug;
  textDynamicFields?: DynamicFieldAttributes[];
};

export const ContentComposer = ({ parts, children, ...rest }: Props) => {
  return (
    <Editor
      indicator={{
        success: theme.colors.primary50,
        error: theme.colors.status.criticalHover,
        thickness: 4,
      }}
      resolver={{ ...parts, Designer }}
    >
      <ContentComposerProvider parts={parts} {...rest}>
        {children}
      </ContentComposerProvider>
    </Editor>
  );
};

ContentComposer.Container = Container;
