import { AreaChartData } from '../area-chart';
import { BarChartData, CategoryBarChartData, CategoryBarChartProps } from '../bar-chart';
import { PieChartData } from '../pie-chart';
import { RadialBarChartData } from '../radial-chart';
import { EmptyStateConfig } from '../types';

interface EmptyStateCalculator<T> {
  customEmptyStateCalculator?: EmptyStateConfig<T>['customEmptyStateCalculator'];
  data: T;
  defaultCalculation: (data: T) => boolean;
  hideEmptyState?: EmptyStateConfig<T>['hideEmptyState'];
}

const commonEmptyStateCalculator = <T>({
  customEmptyStateCalculator,
  data,
  defaultCalculation,
  hideEmptyState,
}: EmptyStateCalculator<T>): boolean => {
  if (hideEmptyState) {
    return false;
  }

  if (customEmptyStateCalculator) {
    return customEmptyStateCalculator(data);
  }

  return defaultCalculation(data);
};

export const showEmptyStateForBarAndAreaChart = (
  params: Omit<EmptyStateCalculator<AreaChartData | BarChartData>, 'defaultCalculation'>
): boolean =>
  commonEmptyStateCalculator({
    ...params,
    defaultCalculation: (data) => {
      // Default behavior: show empty state if all values are 0
      const hasAllZeroGroupValues = data.groups.every(({ values }) => Object.values(values).every((value) => !value));
      const hasAllZeroMarkers = data.markers ? data.markers?.every(({ value }) => !value) : true;
      return hasAllZeroGroupValues && hasAllZeroMarkers;
    },
  });

export const showEmptyStateForPieAndRadialChart = (
  params: Omit<EmptyStateCalculator<PieChartData | RadialBarChartData>, 'defaultCalculation'>
): boolean =>
  commonEmptyStateCalculator({
    ...params,
    defaultCalculation: (data) => {
      // Default behavior: show empty state if all values are 0
      // The key skipTipRemaining is used to skip the tooltips and should not be considered for the empty state too
      return !data.groups.some(({ name, value }) => name !== 'skipTipRemaining' && !!value);
    },
  });

export const showEmptyStateForGaugeChart = (
  params: Omit<EmptyStateCalculator<number>, 'defaultCalculation'>
): boolean =>
  commonEmptyStateCalculator({
    ...params,
    defaultCalculation: (value) => {
      // Default behavior: show empty state if all value is 0
      return !value;
    },
  });

export const showEmptyStateForCategoryBarChart = ({
  markers,
  ...rest
}: Omit<EmptyStateCalculator<CategoryBarChartData>, 'defaultCalculation'> & {
  markers?: CategoryBarChartProps['markers'];
}): boolean =>
  commonEmptyStateCalculator({
    ...rest,
    defaultCalculation: (data) => {
      // Default behavior: show empty state if all values are 0
      const hasAllZeroValues = Object.values(data || {}).every((values) => {
        return Object.values(values || {}).every((value) => !value);
      });

      const hasAllZeroMarkers = markers
        ? Array.isArray(markers)
          ? markers.every(({ value }) => !value)
          : !markers.value
        : true;

      return hasAllZeroValues && hasAllZeroMarkers;
    },
  });
