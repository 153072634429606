import { ButtonSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { ButtonLinkType, Corners, FrameAlignment, ReviewButtonLinkType } from '../../types';
import { formatFrame, formatLink } from '.';

export type OldButtonSectionProps = {
  type: string;
  backgroundColor?: string;
  corners?: Corners;
  frameAlignment?: FrameAlignment;
  frameColor?: string;
  link?: string;
  linkType?: ButtonLinkType;
  reviewLinkType?: ReviewButtonLinkType;
  paddingBottom?: string;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  text?: string;
  textColor?: string;
};

export const migrateButtonSection = (oldSection: OldButtonSectionProps): ButtonSection => {
  return {
    sectionType: 'ButtonSection',
    buttonColor: oldSection.backgroundColor,
    corners: oldSection.corners,
    frame: formatFrame(oldSection),
    link: formatLink(oldSection),
    textColor: oldSection.textColor,
  };
};
