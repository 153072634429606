import { DestinationType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import DOMPurify from 'dompurify';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useDynamicFields } from '@frontend/content-composer';
import { useAppScopeStore } from '@frontend/scope';
import { useFormField, useModalControl } from '@frontend/design-system';

export const usePreviewModal = (templateHtml?: string) => {
  const { modalProps: previewModalProps, triggerProps: previewTriggerProps } = useModalControl();
  const deviceSizeProps = useFormField({ type: 'optionswitch', value: 'desktop' });

  const { selectedLocationIds: locationIds = [], accessibleLocationData } = useAppScopeStore();
  const locationId = locationIds[0];
  const locationData = accessibleLocationData[locationId!];
  const templateTypeSlug = TemplateType_Slug.BULK_MESSAGE;
  const { bindingsList } = useDynamicFields([templateTypeSlug]);

  const sanitizedText = DOMPurify.sanitize(templateHtml ?? '');
  const { data: transformedText = '' } = TemplatorV2Queries.useRender(
    {
      templateTypeSlug,
      templateString: sanitizedText,
      timezone: locationData?.timezone ?? '',
      bindingsList,
      destinationType: DestinationType_Enum.EMAIL,
      locale: 'en_US', // TODO: get this dynamically somewhere
    },
    {
      select: (data) => data.message.message,
      enabled: !!templateHtml,
    }
  );

  const generatePreview = () => {
    if (transformedText) previewTriggerProps.onClick();
  };

  return {
    deviceSizeProps,
    generatePreview,
    previewModalProps,
    previewTriggerProps,
    transformedText,
  };
};
