import { TextSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { FrameAlignment } from '../../types';
import { formatFrame } from './format-frame';

export type OldTextSectionProps = {
  type: string;
  frameAlignment?: FrameAlignment;
  frameColor?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  text?: string;
};

export const migrateTextSection = (oldSection: OldTextSectionProps): TextSection => {
  return {
    sectionType: 'TextSection',
    frame: formatFrame(oldSection),
    text: oldSection.text,
  };
};
