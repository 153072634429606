import { Fragment, memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { theme } from '@frontend/theme';
import { EmptyData, Tooltip, TooltipData } from '../atoms';
import { useChartContext } from '../chart.provider';
import { EMPTY_STATE_MIN_HEIGHT } from '../constants';
import { defaultFormatter, showEmptyStateForPieAndRadialChart } from '../helpers';
import { renderBackgroundCircle } from './background-circle';
import { ChartDataAtom, RadialBarChartProps } from './types';

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
const innerRadiusPercent = '75%';
const outerRadiusPercent = '125%';

export const RadialChart = memo(
  ({
    appearance = {},
    data = {
      groups: [],
    },
    formatValue = defaultFormatter,
  }: RadialBarChartProps) => {
    const {
      barSize = 40,
      borderRadius = 8,
      gradientColors,
      height = 240,
      hideTooltip,
      margin = {},
      maxPolarAxisValue,
      minWidth,
      polarAxisFillColor,
      polarAxisStrokeColor,
      polarAxisStrokeDasharray = '5 5',
      polarAxisStrokeWidth,
      radialStrokeColor,
      radialStrokeWidth,
      width = '100%',
    } = appearance;
    const updatedMargin = { ...defaultMargin, ...margin };
    const { activeLegends, colors, commonTooltipLabel, emptyStateConfig, labels, setPrimaryLegendsList } =
      useChartContext();
    const [hoveredGroup, setHoveredGroup] = useState<string | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const showEmptyState = useMemo(() => {
      return showEmptyStateForPieAndRadialChart({
        ...emptyStateConfig,
        data,
      });
    }, [data, emptyStateConfig]);

    const handleMouseLeave = useCallback(() => {
      setHoveredGroup(null);
    }, []);

    const handleMouseMove = useCallback((e: MouseEvent, groupName: string) => {
      setHoveredGroup(groupName);
      setTooltipPosition({
        x: e.clientX,
        y: e.clientY,
      });
    }, []);

    const { chartData, legendsFromData, tooltipData } = useMemo(() => {
      const chartData: ChartDataAtom[] = [];
      const legendsSet = new Set<string>();
      const tooltipData: Record<string, TooltipData> = {};

      data?.groups.forEach(({ name, value }) => {
        chartData.push({ [name]: value });
        legendsSet.add(name);
        tooltipData[name] = {
          color: colors[name] || theme.colors.neutral50,
          formattedValue: formatValue(value),
          id: name,
          label: commonTooltipLabel || labels?.[name] || name,
        };
      });

      tooltipData['backfill'] = {
        color: polarAxisStrokeColor || theme.colors.neutral50,
        formattedValue: formatValue(maxPolarAxisValue || 0),
        id: 'backfill',
        label: labels?.backfill,
      };

      return {
        chartData,
        legendsFromData: Array.from(legendsSet),
        tooltipData,
      };
    }, [colors, commonTooltipLabel, data?.groups, formatValue, labels]);

    useEffect(() => {
      setPrimaryLegendsList(legendsFromData);
    }, [legendsFromData]);

    return (
      <div
        css={styles.mainWrapper}
        style={{
          height,
          minHeight: showEmptyState ? EMPTY_STATE_MIN_HEIGHT : height,
          minWidth: minWidth,
          width,
        }}
      >
        {!showEmptyState ? (
          <ResponsiveContainer height='100%' width='100%'>
            <RadialBarChart
              barSize={barSize}
              data={chartData}
              endAngle={-270}
              innerRadius={innerRadiusPercent}
              margin={updatedMargin}
              maxBarSize={barSize}
              onMouseLeave={handleMouseLeave}
              outerRadius={outerRadiusPercent}
              startAngle={90}
            >
              {!!maxPolarAxisValue && (
                <PolarAngleAxis angleAxisId={0} domain={[0, maxPolarAxisValue]} tick={false} type='number' />
              )}

              {/* TODO :: Background has hardcoded placement for now. If the usage of this chart increases then we will need to update it to make it dynamic or remove it completely. */}
              {renderBackgroundCircle({
                onMouseLeave: handleMouseLeave,
                onMouseMove: (e) => handleMouseMove(e, 'backfill'),
                polarAxisFillColor,
                polarAxisStrokeColor,
                polarAxisStrokeDasharray,
                polarAxisStrokeWidth,
              })}

              {activeLegends.map((legend) => {
                // This prevents from rendering bars without data when custom legends are provided
                if (!legendsFromData?.includes(legend)) {
                  return null;
                }

                const color = colors[legend] || theme.colors.neutral50;
                const hasGradient = !!(gradientColors && gradientColors[legend]);
                const defId = hasGradient ? `def-${legend.replace(/[^a-zA-Z0-9]/g, '')}` : '';

                // Some of the props are not exposed by the library (but they exists) and passing them directly to the component throws TS errors
                // Hence using the spread operator to pass the props
                const props = {
                  clockWise: true,
                };

                return (
                  <Fragment key={legend}>
                    {hasGradient && (
                      <defs>
                        <linearGradient id={defId} x1='0%' y1='0%' x2='100%' y2='100%'>
                          <stop offset='0%' stopColor={gradientColors[legend].start} />
                          <stop offset='100%' stopColor={gradientColors[legend].end} />
                        </linearGradient>
                      </defs>
                    )}

                    <RadialBar
                      {...props}
                      background={false}
                      cornerRadius={borderRadius}
                      dataKey={legend}
                      fill={hasGradient ? `url(#${defId})` : color}
                      onMouseLeave={handleMouseLeave}
                      onMouseMove={(_props, _i, event) => handleMouseMove(event, legend)}
                      stroke={radialStrokeColor}
                      strokeWidth={radialStrokeWidth}
                    />
                  </Fragment>
                );
              })}

              {typeof data?.centerMetric !== 'undefined' && data.centerMetric !== null && (
                <text
                  x='50%'
                  y='50%'
                  dy={12}
                  style={{
                    fontSize: theme.fontSize(36),
                    fontWeight: theme.font.weight.bold,
                  }}
                  textAnchor='middle'
                >
                  {data?.centerMetric}
                </text>
              )}
            </RadialBarChart>
          </ResponsiveContainer>
        ) : (
          <EmptyData emptyStateConfig={emptyStateConfig} />
        )}
        {hoveredGroup && !hideTooltip && (
          <Tooltip
            data={Object.values(tooltipData)}
            itemType={'segment'}
            skipActiveLegendsCheck
            xPos={tooltipPosition.x}
            yPos={tooltipPosition.y}
          />
        )}
      </div>
    );
  }
);

RadialChart.displayName = 'RadialChart';

const styles = {
  mainWrapper: css`
    display: flex;
    align-items: center;
    path.recharts-sector:focus {
      outline: none;
    }
  `,
};
