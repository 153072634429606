import { FC, useState, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { BuilderTypes } from '@frontend/forms-builder-engine';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { DragAndDrop } from '../../../../constants';
import { useFormBuilderStore } from '../../../../provider';
import { DraggableUtils, DroppableUtils } from '../../../../utils';
import DraggableField from '../draggable-field/draggable-field.component';
import { FieldItemAppearance } from '../draggable-field/draggable-field.styles';
import { sectionTemplateFieldsStyle, textStyle } from './section-templates.styles';

const DROPPABLE_ID = DroppableUtils.sectionTemplate.getDroppableId();
const SECTION_TEMPLATES_WITH_WRITEBACK: BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey[] = [
  'patient',
];

export const SectionTemplates: FC = () => {
  const { t } = useTranslation('forms');
  const { sectionTemplatesOrder, usedSectionTemplates, searchFieldTerm } = useFormBuilderStore([
    'sectionTemplatesOrder',
    'usedSectionTemplates',
    'searchFieldTerm',
  ]);
  const [filteredSectionTemplates, setFilteredSectionTemplates] = useState<
    BuilderTypes.BuilderFormElementTypes.SectionTemplates.Order[]
  >([]);

  useEffect(() => {
    const filteredTemplates = sectionTemplatesOrder.filter(({ label }) => {
      if (searchFieldTerm.trim() !== '') {
        return label.toLowerCase().includes(searchFieldTerm.toLowerCase());
      }

      return true;
    });

    setFilteredSectionTemplates(filteredTemplates);
  }, [sectionTemplatesOrder, searchFieldTerm]);

  if (filteredSectionTemplates.length === 0) {
    return (
      <Text textAlign='center' color='subdued' css={textStyle}>
        {t('No matching results found!')}
      </Text>
    );
  }

  return (
    <Droppable droppableId={DROPPABLE_ID} isDropDisabled type={DragAndDrop.DroppableType.SECTION}>
      {(provided) => (
        <ul css={sectionTemplateFieldsStyle} {...provided.droppableProps} ref={provided.innerRef}>
          {filteredSectionTemplates.map(({ key, label, trackingId }, index) => {
            let appearance: FieldItemAppearance = 'default';
            if (!SECTION_TEMPLATES_WITH_WRITEBACK.includes(key)) {
              appearance = 'subdued';
            }

            if (usedSectionTemplates[key]) {
              appearance = 'disabled';
            }

            return (
              <DraggableField
                key={key}
                index={index}
                displayName={label}
                draggableId={DraggableUtils.sectionTemplate.getDraggableId(key)}
                appearance={appearance}
                disabledMessage={t('This section template is already in use')}
                subduedMessage={t('This section template is not syncable')}
                trackingId={trackingId}
              />
            );
          })}
        </ul>
      )}
    </Droppable>
  );
};
