const droppablePrefixes = {
  sectionTemplates: 'section-templates',
};

/**
 * @returns The droppable ID for the section templates (to hold the sections to drag from).
 */
export function getDroppableId(): string {
  return droppablePrefixes.sectionTemplates;
}
