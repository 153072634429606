import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { Chart } from '@frontend/charts';
import { i18next, Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, SkeletonLoaders, Text } from '@frontend/design-system';
import { usePreviousTimePeriodLabel } from '../../hooks/use-previous-time-period-label';
import { CallIntelMockData } from './demo-data';
import { useCallIntelLocations, useCallIntelShallowStore } from './hooks';

export type InsightStats = {
  changeType: CallIntelTypes.ChangeType;
  isPartialCurrent: boolean;
  isPartialPrevious: boolean;
  percentageChange: number;
  previousValue: number;
  previousRate: number;
  currentValue: number;
  currentRate: number;
  previousTotalValue: number;
  currentTotalValue: number;
};

type InsightChartProps = {
  chartType: CallIntelTypes.InsightChartType;
  currentTimePeriodLabel: string;
  insightStats: InsightStats;
  insightType: CallIntelTypes.InsightType;
  isMultiLocation?: boolean;
  locationCount: number;
};

type CustomToolTipTitleProps = {
  label: string | ReactNode;
  isMultiLocation?: boolean;
  locationCount: number;
};

type Props = {
  chartType: CallIntelTypes.InsightChartType;
  isLoading: boolean;
  insightStats: InsightStats;
  insightText: string | React.ReactNode;
  insightType: CallIntelTypes.InsightType;
  isDemoAccount?: boolean;
};

type TitleProps = {
  insightStats: InsightStats;
  insightType: CallIntelTypes.InsightType;
};

const chartSize = 84;

const demoLocations = CallIntelMockData.dummyLocationNames();

export const getPartialComparisonMessage = ({
  isPartialCurrent,
  isPartialPrevious,
}: {
  isPartialCurrent: boolean;
  isPartialPrevious: boolean;
}): string => {
  const partialMessages = {
    bothPartial: i18next.t('*Partial comparison. Data for selected and previous period are limited.', {
      ns: 'analytics',
    }),
    currentPartial: i18next.t('*Partial comparison. Selected time period is ongoing.', { ns: 'analytics' }),
    previousPartial: i18next.t('*Partial comparison. Limited data in previous period.', { ns: 'analytics' }),
  };

  if (isPartialCurrent && isPartialPrevious) return partialMessages.bothPartial;
  if (isPartialCurrent) return partialMessages.currentPartial;
  if (isPartialPrevious) return partialMessages.previousPartial;
  return '';
};

const generateTooltipData = (id: string, insightStats: InsightStats, insightType: CallIntelTypes.InsightType) => {
  const tooltipData = {
    scheduled: [
      {
        color: theme.colors.success40,
        formattedValue: `${insightStats.currentRate}%`,
        id,
        label: i18next.t('Scheduled Rate', {
          ns: 'analytics',
        }),
      },
      {
        color: theme.colors.success40,
        formattedValue: insightStats.currentValue,
        id,
        label: i18next.t('Scheduled'),
        subLabel: i18next.t('Out of {{totalValue}} Opportunities', {
          ns: 'analytics',
          totalValue: insightStats.currentTotalValue,
        }),
      },
      {
        color: theme.colors.warning20,
        formattedValue: `${100 - insightStats.currentRate}%`,
        id,
        label: i18next.t('Unscheduled Rate', {
          ns: 'analytics',
        }),
      },
      {
        color: theme.colors.warning20,
        formattedValue: insightStats.currentTotalValue - insightStats.currentValue,
        id,
        label: i18next.t('Unscheduled'),
        subLabel: i18next.t('Out of {{totalValue}} Opportunities', {
          ns: 'analytics',
          totalValue: insightStats.currentTotalValue,
        }),
      },
    ],
    unresolved: [
      {
        color: theme.colors.critical50,
        formattedValue: `${insightStats.currentRate}%`,
        id,
        label: i18next.t('Unresolved Call Rate', {
          ns: 'analytics',
        }),
      },
      {
        color: theme.colors.critical50,
        formattedValue: insightStats.currentValue,
        id,
        label: i18next.t('Unresolved Calls', {
          ns: 'analytics',
        }),
        subLabel: i18next.t('Out of {{totalValue}} Calls Analyzed', {
          ns: 'analytics',
          totalValue: insightStats.currentTotalValue,
        }),
      },
    ],
  };

  return tooltipData[insightType];
};

const Loader = () => <SkeletonLoaders.Loader css={styles.wrapper} height='132px' />;

const Title = ({ insightType, insightStats }: TitleProps) => {
  const { changeType: statusChangeType, percentageChange: statusChange } = insightStats;

  const { t } = useTranslation('analytics');

  switch (statusChangeType) {
    case 'increased': {
      return insightType === 'scheduled' ? (
        <Text>
          <Trans t={t}>
            <Text as='span' weight='bold'>
              Scheduled Rate has{' '}
            </Text>{' '}
            <Text as='span' color='success' weight='bold'>
              improved by {{ statusChange }}%
            </Text>{' '}
            <Text as='span' weight='bold'>
              compared to the previous period.
            </Text>
          </Trans>
        </Text>
      ) : (
        <Text>
          <Trans t={t}>
            <Text as='span' weight='bold'>
              Unresolved Call Rate has{' '}
            </Text>
            <Text as='span' weight='bold' color='error'>
              increased by {{ statusChange }}%
            </Text>{' '}
            <Text as='span' weight='bold'>
              compared to the previous period.
            </Text>
          </Trans>
        </Text>
      );
    }
    case 'decreased': {
      return insightType === 'scheduled' ? (
        <Text>
          <Trans t={t}>
            <Text as='span' weight='bold'>
              Scheduled Rate has{' '}
            </Text>{' '}
            <Text as='span' color='error' weight='bold'>
              declined by {{ statusChange }}%
            </Text>{' '}
            <Text as='span' weight='bold'>
              compared to the previous period.
            </Text>
          </Trans>
        </Text>
      ) : (
        <Text>
          <Trans t={t}>
            <Text as='span' weight='bold'>
              Unresolved Call Rate has{' '}
            </Text>
            <Text as='span' weight='bold' color='success'>
              decreased by {{ statusChange }}%
            </Text>{' '}
            <Text as='span' weight='bold'>
              compared to the previous period.
            </Text>
          </Trans>
        </Text>
      );
    }
    case 'unchanged': {
      return insightType === 'scheduled' ? (
        <Text as='span' weight='bold'>
          {t('Scheduled Rate is unchanged compared to the previous period.')}
        </Text>
      ) : (
        <Text as='span' weight='bold'>
          {t('Unresolved Call Rate is unchanged compared to the previous period.')}
        </Text>
      );
    }
    default: {
      const { currentTotalValue, previousTotalValue } = insightStats;

      if (currentTotalValue === 0 && previousTotalValue === 0) {
        return (
          <Text color='subdued' weight='bold'>
            {t('Service Quality is a new feature. No data is available for this period and the previous period')}.
          </Text>
        );
      } else if (previousTotalValue === 0) {
        return (
          <Text color='subdued' weight='bold'>
            {t('Service Quality is a new feature. No data is available for the previous period for comparison')}.
          </Text>
        );
      }
      return (
        <Text color='subdued' weight='bold'>
          {t('No data for this period and/or the previous period. Insights are unavailable')}.
        </Text>
      );
    }
  }
};

export const CustomToolTipTitle = ({ label, isMultiLocation, locationCount }: CustomToolTipTitleProps) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.tooltipWrapper}>
      <Text color='subdued' size='small'>
        {label}
      </Text>
      {isMultiLocation && (
        <Chip.Location>
          {t('{{count}} {{label}}', { count: locationCount, label: locationCount > 1 ? 'Locations' : 'Location' })}
        </Chip.Location>
      )}
    </div>
  );
};

const InsightChart = ({
  chartType,
  currentTimePeriodLabel,
  insightType,
  insightStats,
  isMultiLocation,
  locationCount,
}: InsightChartProps) => {
  switch (chartType) {
    case 'bar': {
      const data = {
        data: {
          groups: [
            {
              name: 'primary',
              values: {
                primary: insightStats.currentRate,
                secondary: insightStats.currentRate === 0 ? 0 : 100 - insightStats.currentRate,
                noData: insightStats.currentRate === 0 ? 100 : 0,
              },
            },
          ],
        },
        maxValue: 100,
      };

      return (
        <Chart
          colors={{
            noData: theme.colors.neutral20,
            primary: theme.colors.success40,
            secondary: theme.colors.warning20,
          }}
          css={[
            styles.chartStyles,
            css`
              margin-top: ${theme.spacing(3)};
            `,
          ]}
        >
          <Chart.BarChart
            appearance={{
              barRadius: 4,
              barSize: 32,
              collectiveTooltip: true,
              customTooltipTitle: () => (
                <CustomToolTipTitle
                  label={currentTimePeriodLabel}
                  isMultiLocation={isMultiLocation}
                  locationCount={locationCount}
                />
              ),
              customTooltipData: (id) => generateTooltipData(id, insightStats, insightType),
              groupsGap: 0,
              layout: 'vertical',
              maxValue: 100,
              mode: 'stacked',
              width: chartSize,
            }}
            data={data.data}
          />
        </Chart>
      );
    }
    case 'gauge': {
      return (
        <Chart
          colors={{ value: theme.colors.critical30 }}
          css={styles.chartStyles}
          emptyStateConfig={{
            hideEmptyState: true,
          }}
        >
          <Chart.GaugeChart
            appearance={{
              collectiveTooltip: true,
              customTooltipTitle: (
                <CustomToolTipTitle
                  label={currentTimePeriodLabel}
                  isMultiLocation={isMultiLocation}
                  locationCount={locationCount}
                />
              ),
              customTooltipData: (id) => generateTooltipData(id, insightStats, insightType),
              height: chartSize,
              hideLabel: true,
              hideTooltip: false,
              width: chartSize,
              innerRadius: 22,
              outerRadius: 42,
              centerLabelFontSize: 16,
              margin: { top: 40 },
            }}
            value={insightStats.currentRate}
          />
        </Chart>
      );
    }
    default:
      return null;
  }
};

export const InsightCard = ({ chartType, isLoading, insightType, insightText, insightStats, isDemoAccount }: Props) => {
  const {
    filterHintText,
    filters: { locations },
  } = useCallIntelShallowStore('filterHintText', 'filters');
  const { isMultiLocation } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });
  const previousTimePeriodLabel = usePreviousTimePeriodLabel(filterHintText);

  const partialMessage = getPartialComparisonMessage({
    isPartialCurrent: insightStats.isPartialCurrent,
    isPartialPrevious: insightStats.isPartialPrevious,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.insightContainer}>
        <Icon name='info' size={24} css={styles.insightIcon} />
        <div css={styles.titleContainer}>
          <Title insightType={insightType} insightStats={insightStats} />
          <div css={styles.insightTextContainer}>
            <Text color='subdued' css={styles.timePeriodText} size='medium'>
              {previousTimePeriodLabel}
            </Text>{' '}
            {insightText}
          </div>
          {!!partialMessage && insightStats.changeType !== 'noData' && (
            <Text color='subdued' css={styles.partialMsgText} size='small'>
              {partialMessage}
            </Text>
          )}
        </div>
      </div>
      <InsightChart
        chartType={chartType}
        currentTimePeriodLabel={filterHintText}
        insightType={insightType}
        insightStats={insightStats}
        isMultiLocation={isMultiLocation}
        locationCount={locations?.length ?? 0}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(2)};
    background-color: ${theme.colors.neutral5};
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-width: 310px;
  `,
  insightContainer: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
  insightIcon: css`
    min-width: ${theme.spacing(3)};
  `,
  titleContainer: css`
    display: flex;
    gap: ${theme.spacing(1)};
    flex-direction: column;
    height: 100%;
  `,
  insightTextContainer: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
  `,
  timePeriodText: css`
    text-wrap: noWrap;
  `,
  partialMsgText: css`
    font-style: italic;
  `,
  chartStyles: css`
    margin-bottom: 0px;

    > div {
      border: none;
      padding: 0px;
      border-radius: ${theme.borderRadius.none};
    }
  `,
  tooltipWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `,
};
