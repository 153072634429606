import { useEffect } from 'react';
import { TextSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { DestinationType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import DOMPurify from 'dompurify';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useAppScopeStore } from '@frontend/scope';
import { useComposerSection, useDynamicFields } from '../../hooks';
import { ComposerSectionOutputProps } from '../../types';
import { getFrameStyle } from '../../utils';
import { useContentComposer } from '../content-composer-provider';

// TODO: Handle what the RTE outputs better, IE: links kinda suck right now.
export const TextOutput = ({ onRenderReady, renderMode }: ComposerSectionOutputProps) => {
  const { props } = useComposerSection<TextSection>();
  const { composerMode, templateTypeSlug } = useContentComposer();
  const isFeedbackMode = composerMode === 'feedback';

  const { selectedLocationIds: locationIds = [], accessibleLocationData } = useAppScopeStore();
  const locationId = locationIds[0];
  const locationData = accessibleLocationData[locationId!];
  const { bindingsList } = useDynamicFields([templateTypeSlug]);

  // spacing that is added to the editor-paragraph class isn't customizable by the user, so removing it so they can set it themselves (TODO: add in line-height: 100% to the styles once confirmed by Michelle)
  const sanitizedText = DOMPurify.sanitize(props.text ?? '');
  const { data: transformedText = '' } = TemplatorV2Queries.useRender(
    {
      templateTypeSlug,
      templateString: sanitizedText,
      timezone: locationData?.timezone ?? '',
      bindingsList,
      destinationType: isFeedbackMode ? DestinationType_Enum.SMS : DestinationType_Enum.EMAIL,
      locale: 'en_US', // TODO: get this dynamically somewhere
    },
    {
      select: (data) => data.message.message,
      enabled: renderMode === 'preview',
      onSettled: onRenderReady,
    }
  );

  useEffect(() => {
    if (renderMode !== 'preview' || !!transformedText) {
      onRenderReady?.();
    }
  }, [transformedText]);

  return (
    <section
      style={{
        ...getFrameStyle(props.frame),
        ...(isFeedbackMode && { lineHeight: '1.5' }),
      }}
      dangerouslySetInnerHTML={{ __html: renderMode === 'preview' ? transformedText : sanitizedText }}
    />
  );
};

export const TextOutputStyles = () => (
  <style>
    {`.editor-paragraph {
        margin: 0;
      }
      @media (max-width: 600px) {
        section {
          padding-left: 20px !important;
          padding-right: 20px !important;
        }
      }
    `}
  </style>
);
