import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { LogoSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { PercentCrop } from 'react-image-crop';
import { Corners, FrameAlignment } from '../../types';
import { convertMediaToImage } from '../convert-media-to-image';
import { formatFrame } from './format-frame';

export type OldLogoSectionProps = {
  type: string;
  corners?: Corners;
  crops?: PercentCrop[];
  frameAlignment?: FrameAlignment;
  frameColor?: string;
  height?: string;
  images?: (Media | undefined)[];
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
};

export const migrateLogoSection = (oldSection: OldLogoSectionProps): LogoSection => {
  const logoImage = oldSection.images?.[0];
  const logoImageCrop = oldSection.crops?.[0];

  return {
    sectionType: 'LogoSection',
    corners: oldSection.corners,
    frame: formatFrame(oldSection),
    height: oldSection.height,
    image: convertMediaToImage(logoImage, logoImageCrop),
  };
};
